import {
	append, concat, isEmpty, map, not,
	prop, uniq, view,
} from 'ramda';

import config from '../config.json';
import actions from './actions';
import { createReducer } from '@lib/utils/redux';

import { actions as publications } from '@state/publications';
import { actions as feeds } from '@state/feeds';

const reducerImports = {
    publications,
    feeds,
};

const reducers = {
    'publications.getPublicationBacklogSuccess': (state, action) => {

        const publications = action.payload.publications;

		state.loaded.publications = uniq(concat(
			state.loaded.publications,
			map(prop('_id'), publications),
		));
	},
	'feeds.getFeedsSuccess': (state, action) => {

        const { payload, meta } = action;
        const feeds = payload.feeds;
		const publicationId = meta.req.publicationId;

		if (publicationId) {
			state.loaded.publicationFeeds = uniq(append(
				publicationId,
				state.loaded.publicationFeeds
			));
		}

		if (not(isEmpty(feeds))) {
			state.loaded.feeds = uniq(concat(
				state.loaded.feeds,
				map(prop('_id'), feeds),
			));
		}
	},
    'feeds.getPublicationFeedsSuccess': (state, action) => {

        const { payload, meta } = action;
        const feeds = payload.feeds;
		const publicationId = meta.req.publicationId;

		if (publicationId) {
			state.loaded.publicationFeeds = uniq(append(
				publicationId,
				state.loaded.publicationFeeds
			));
		}

		if (not(isEmpty(feeds))) {
			state.loaded.feeds = uniq(concat(
				state.loaded.feeds,
				map(prop('_id'), feeds),
			));
		}
	},
    setFeed: (state, action) => {
        state.feedId = action.payload.feedId;
    },
    setPublication: (state, action) => {
        state.publicationId = action.payload.publicationId;
    },
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
