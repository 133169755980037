import config from '@nl/publications/config.json';
import actions from './actions';
import reducer from './reducers';
import saga from './sagas';
import selectors from './selectors';

export { actions };
export { saga };
export { selectors };
export { reducer };

export default {
    config,
    actions,
    saga,
    reducer,
    selectors,
};
