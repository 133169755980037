import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';

import { actions, selectors } from '@state/tags';
import { bindThis } from '@lib/utils/react';
import Tile from '@ui/components/tile';

import s from './styles.scss';
import c from '@ui/components/tile/styles.scss';

const sx = cn.bind(s);
const cx = cn.bind(c);

class TagsTile extends React.Component {

	constructor() {
		super();

		this.state = {
			name: '',
		};

		bindThis(this, [ 'onChange', 'onKeyPress', 'create' ]);
	}

	onChange(e) {
		this.setState({ name: e.target.value });
	}

	onKeyPress(e) {

		if (e.key == 'Enter') {
			this.create();
		}

		return false;
	}

	async create(e) {

		const { name } = this.state;
		const { createTag } = this.props;
		const tag = await createTag({ name });

		this.setState({ name: '' });
	}

	componentDidMount() {
		const { getTags } = this.props;
		getTags({});
	}

	render() {

		const { tags, deleteTag } = this.props;
		const { name } = this.state;

		return (
			<Tile
				title='Tags'
				className={ sx('tagCell') }
				flexGrow={ 1 }
			>
				<div className={ cx('topInputRow') }>
					<input
						type='text'
						value={ name }
						className={ cx('fieldInput') }
						onChange={ this.onChange }
						onKeyPress={ this.onKeyPress }
					/>
					<button
						className={ cx('btnSubmit') }
						onClick={ this.create }
					>
						<i className='fa fa-plus' />
						&nbsp;Add
					</button>
				</div>
				<ul className={ cx('list') }>{
					 tags && tags.map(t => 
						<li key={ t._id } className={ cx('listItem') }>
							<span className={ cx('listItemContent') }>{ t.name }</span>
							<button
								className={ cx('btnDelete') }
								onClick={ () => deleteTag({ tagId: t._id }) }
							>×</button>
						</li>
					)
				}</ul>
			</Tile>
		);
	}
}

export default connect(
	state => ({
		tags: selectors.getTags(state),
	}),
	{
		getTags: actions.getTagsRequest,
		createTag: actions.createTagRequest,
		deleteTag: actions.deleteTagRequest,
	}
)(TagsTile);
