import LoginPage from './pages/login';
import Dashboard from './pages/dashboard';
import PollerPage from './pages/poller';
import FeedsPage from './pages/feeds';
import FeedPage from './pages/feed';
import PublicationsPage from './pages/publications';
//import PublicationPage from './pages/publication';

const PublicationPage = () => {};

export default [
    {
        path: '/',
        action: () => {
            return <LoginPage />;
        },
    },
    {
        path: '/dashboard',
        action: () => {
            return <Dashboard />;
        },
    },
    {
        path: '/poller',
        action: () => {
            return <PollerPage />;
        },
    },
    {
        path: '/publications',
        children: [
            {
                path: '',
                action: () => {
                    return <PublicationsPage />;
                },
            },
            {
                path: '/:publicationId',
                action: ctx => {
                    return <PublicationPage publicationId={ ctx.params.publicationId } />;
                },
            },
        ],
    },
    {
        path: '/feeds',
        children: [
            {
                path: '',
                action: () => {
                    return <FeedsPage />;
                },
            },
            {
                path: '/:feedId',
                action: ctx => {
                    return <FeedPage feedId={ ctx.params.feedId } />;
                },
            },
        ],
    },
];
