//import socket from '@lib/socketio';
//import cn from 'classnames/bind';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { basicApi2 } from '@lib/utils/graphql-api';
import _columns from './columns';

import $users from '@state/users';
import feedsApi, { gql } from '@state/feeds/api';
import { selectors as feedSels, actions as feedActs } from '@state/feeds';

import Workspace from '@ui/components/workspace';
import Table from '@ui/pages/feeds/ui/table';
import Pagination from '@ui/components/pagination';
import ColumnSelect from '@ui/components/column-select';
import ColumnOpt from '@ui/components/column-opt';

import s from './styles.scss';

let timeoutId;
let loaded;

const filterOptionMakers = {
	__default: vals => vals.map(v => ({ label: v, value: v })),
	publication: ps => ps.map(p => ({
		label: p.name,
		value: p._id,
	})),
};

const addFilterOptions = filterOptionMakers => distinct =>
Object.entries(distinct).reduce(
	(opts, [ key, _options ]) => {

		let options;

		if (key in filterOptionMakers) {
			options = filterOptionMakers[ key ](_options);
		} else {
			options = filterOptionMakers.__default(_options);
		}

		return {
			...opts,
			[ key ]: options,
		};
	},
	{}
);

export default connect(
    (state, props) => ({
        user: $users.selectors.getLoggedInUser(state, props),
    }),
    {
        updateSettings: $users.actions.updateSettingsRequest,
		getFeeds: feedActs.getFeedsRequest,
    }
)(
    ({ user, getFeeds, updateSettings }) => {

        if (!user) return null;

        const settings = user?.settings?.admin;
        const [ feeds, updateFeeds ] = useState([]);
        const [ pageSize, setPageSize ] = useState(settings?.pageSize || 15);
        const [ page, setPage ] = useState(settings?.page || 1);
        const [ total, updateTotal ] = useState(0);
        const [ numItems, updateNumItems ] = useState(0);
        const [ hiddenColumns, setHiddenColumns ] = useState(
            settings?.hiddenColumns || []
        );
        const [ sort, setSort ] = useState(settings?.sort || []);

        const [ columns, setColumns ] = useState(
            _columns.map(c => {
                const hid = hiddenColumns.includes(c.key);
                const s = sort.find(s => s.field === c.key);
                return {
                    ...c,
                    visible: !hid,
                    sort: s ? s.value : 0,
                };
            })
        );
		const [ distinct, setDistinct ] = useState({});

        const [ filters, setFilters ] = useState(
			_columns.map(
				c => ({
					key: c.key,
					...c.filter,
                    value: (
                        c.key in settings?.filters &&
                        settings.filters[ c.key ] !== null
                    ) ?
                        settings.filters[ c.key ] :
                        c.filter.value
				})
			)
		);
		const [ filterOptions, setFilterOptions ] = useState([]);
		const [ filterCtls, setFilterCtls ] = useState(false);
		const [ activeFilters, setActiveFilters ] = useState({});

        const totalPages = Math.ceil(total / pageSize);

        const addFilterOpts = addFilterOptions(filterOptionMakers);

        useEffect(
            () => {
                
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(
                    () => {
                        basicApi2(
                            gql.queries.getFeeds(),
                            {
                                pageOpts: {
                                    page,
                                    pageSize,
                                    sort,
                                    filters: activeFilters,
                                },
                                withInput: { publication: true, tags: true },
                            }
                        ).then(res => {
                            if (res.success) {

                                const data = res.data.results;

                                updateFeeds(data.feeds);
                                updateTotal(data.total);
                                updateNumItems(data.feeds.length);

                                if (data.distinct) {
                                    setDistinct(data.distinct);
                                    setFilterOptions(addFilterOpts(data.distinct));
                                }
                                loaded = true;
                            }
                        });

                        if (loaded) {
                            updateSettings({
                                userId: user._id,
                                settings: {
                                    ...user.settings,
                                    admin: {
                                        page, 
                                        pageSize,
                                        sort,
                                        filters: activeFilters,
                                        hiddenColumns,
                                    },
                                }
                            });
                        }
                    },
                    1000
                );
            },
            [ page, pageSize, sort, activeFilters, hiddenColumns ]
        );

        useEffect(
			() => {
				const _filters = filters.reduce(
					(fs, f) => {

						if (f.type === 'text' && f.value) {
							return { ...fs, [ f.key ]: f.value };

                        } else if (f.type === 'number' && f.value) {
							return { ...fs, [ f.key ]: +f.value };

						} else if (
                            f.type === 'boolean' && [ '0', '1' ].includes(f.value)
                        ) {
							return { ...fs, [ f.key ]: f.value };

						} else if (f.type === 'list' && f.value.length) {
							return { ...fs, [ f.key ]: f.value };

						} else if (f.type === 'date' && (f.value[ 0 ] || f.value[ 1 ])) {
							return { ...fs, [ f.key ]: f.value };
						}
						return fs;
					},
					{}
				);

				setActiveFilters(_filters);
			},
			[ filters ]
		);

        return (
            <Workspace>
                <div className={ s.root }>
                    <div className={ s.header }>
                        <div className={ s.left }>
                            <ColumnSelect
                                data={ columns }
                                onChange={
                                    (col, i) => {

                                        const currVal = col.visible;
                                        const newVal = !currVal;

                                        const _columns = Object.assign(
                                            [],
                                            columns,
                                            {
                                                [ i ]: {
                                                    ...col,
                                                    visible: newVal,
                                                },
                                            }
                                        );

                                        setColumns(_columns);

                                        if (newVal) {
                                            setHiddenColumns(
                                                hiddenColumns.filter(
                                                    v => v !== col.key
                                                )
                                            );
                                        } else {
                                            setHiddenColumns(
                                                [ ...hiddenColumns, col.key ]
                                            );
                                        }
                                        //saveColSetting(_columns);
                                    }
                                }
                                optionEl={ ColumnOpt }
                            />
                            <div
								className={ s.filterBtnCont }
								onClick={ () => setFilterCtls(!filterCtls) }
							>
								<FontAwesomeIcon icon='filter' />
							</div>
                        </div>
                        <div className={ s.center }>
                        </div>
                        <div className={ s.right }>
                            <Pagination
                                config={{
                                    page,
                                    pageSize,
                                    total,
                                    totalPages,
                                    numItems,
                                    //adjacent: 1,
                                    //boundWidth: 3,
                                    //boundReach: 5,
                                    //separator: '...',
                                    onPageSizeChange: v => {
                                        setPageSize(v.value);
                                    },
                                    prevPage: () => {
                                        if (page > 1) {
                                            setPage(page - 1);
                                        }
                                    },
                                    nextPage: () => {
                                        if (page < totalPages) {
                                            setPage(page + 1);
                                        }
                                    },
                                    onPageChange: n => {
                                        if (page !== n) {
                                            setPage(n);
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={ s.tableCont }>
                        <Table
                            feeds={ feeds }
                            columns={ columns }
                            filters={ filters }
                            filterOptions={ filterOptions }
                            filterCtls={ filterCtls }
                            sort={ sort }
                            setColumns={ setColumns }
                            setFilters={ setFilters }
                            setFilterCtls={ setFilterCtls }
                            setSort={ setSort }
                            updateFeeds={ updateFeeds }
                        />
                    </div>
                </div>
            </Workspace>
        );
    }
);
