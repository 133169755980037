import { fork, call, cancel, cancelled, take, put, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import api from './api';

const updateLocalStore = data => {

    let user = data?.user;

    if (!user && data && typeof data === 'object') {
        const entries = Object.entries(data);

        if (entries.length === 1) {
            const [ key, val ] = entries[0];
            user = data[ key ].user;
        }
    }

    if (user) {
        const loginState = JSON.parse(localStorage.getItem('loginState'));
        localStorage.setItem(
            'loginState',
            JSON.stringify({ ...loginState, user })
        );
    }
};

const apiSaga = (api, actions) => function* (action) {

    try {
        const response = yield call(api, action.payload);

        if (!response.success) {
            yield put(actions.failure(response.data));

        } else {
            yield call(updateLocalStore, response.data);
            yield put(actions.success(
                response.data,
                { req: action.payload }
            ));

            return response.data;
        }
    } catch (e) {
        yield put(actions.failure({ message: e.toString() }));
    }
};

const acts = groupActions(actions);

const getUser = function* () {

    const workerSaga = createAPISaga(api.getUser, acts.getUser);

    yield takeLatest(acts.getUser.request, workerSaga);
};

const setCurrentView = function* () {

    const workerSaga = apiSaga(api.setCurrentView, acts.setCurrentView);

    yield takeLatest(acts.setCurrentView.request, workerSaga);
};

const subscribe = function* () {

    const workerSaga = apiSaga(api.subscribe, acts.subscribe);

    yield takeLatest(acts.subscribe.request, workerSaga);
};

const unsubscribe = function* () {

    const workerSaga = apiSaga(api.unsubscribe, acts.unsubscribe);

    yield takeLatest(acts.unsubscribe.request, workerSaga);
};

const verifyEmail = function* () {

    const workerSaga = apiSaga(api.verifyEmail, acts.verifyEmail);

    yield takeLatest(acts.verifyEmail.request, workerSaga);
};

const addBookmark = function* () {

    const workerSaga = apiSaga(api.addBookmark, acts.addBookmark);

    yield takeLatest(acts.addBookmark.request, workerSaga);
};

const removeBookmark = function* () {

    const workerSaga = apiSaga(api.removeBookmark, acts.removeBookmark);

    yield takeLatest(acts.removeBookmark.request, workerSaga);
};

const updateSettings = function* () {

    const workerSaga = apiSaga(api.updateSettings, acts.updateSettings);

    yield takeLatest(acts.updateSettings.request, workerSaga);
};

export default function* () {

    const sagas = [
        getUser,
        setCurrentView,
        subscribe,
        unsubscribe,
        verifyEmail,
        addBookmark,
        removeBookmark,
        updateSettings,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
