import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { compose as c } from 'ramda';

import app from '@state/app';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
		responsive: app.selectors.getResponsive(state, props),
	})
)(
	c(
		({ responsive, children }) => {

            const mobile = !responsive.md;

            return (
                <section className={ sx('root', { mobile }) }>
                    { children }
                </section>
            )
        }
	)
);
