import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames/bind';

import { APP_BASE_URL } from '@src/env';
import { useClickAway } from '@ui/react-utils';
import $feeds from '@state/feeds';
import $users from '@state/users';

import s from './styles.scss';

const sx = cn.bind(s);

const tf = v => v ? 'Yes' : 'No';

const sortIcons = {
	'-1': 'sort-down',
	'0': 'sort',
	'1': 'sort-up',
};

const timeoutIds = {};

const makeClearFilter = (filters, setFilters, textBuffs, setTextBuffs) => index => () => {

    let value;

    const filter = filters[ index ];

    switch (filter.type) {
        case 'text':
            value = '';
            setTextBuffs({ ...textBuffs, [ filter.key ]: '' });
            break;
        case 'boolean':
            value = '';
            break;
        case 'list':
            value = [];
            break;
        case 'date':
            value = [ '', '' ];
            break;
    }

    const f = Object.assign(
        [],
        filters,
        {
            [ index ]: {
                ...filter,
                value,
            },
        }
    );

    setFilters(f);
};

const columnClickHandlers = (columns, sort, setColumns, setSort) => ({

    onColumnDoubleClick: (i, e) => {

        const col = columns[ i ];

        let sortOrder = sort.filter(({ field, val }) => field !== col.key);

        let newColumns = Object.assign(
            [],
            columns,
            {
                [ i ]: {
                    ...col,
                    sort: 0
                },
            }
        );

        setSort(sortOrder);
        setColumns(newColumns);
    },
    onColumnClick: (i, e) => {
    
        const col = columns[ i ];
        const currentSort = col.sort;

        let sortOrder = [ ...sort ];
        let newSort, newColumns;

        if (currentSort === 0) {
            newSort = 1;
            sortOrder = [ ...sortOrder, { field: col.key, value: newSort }];

        } else {

            newSort = (currentSort === -1) ? 1 : -1;

            const orderIndex = sort.findIndex(
                ({ field, value }) => field === col.key
            );

            sortOrder = Object.assign(
                [],
                sort,
                { [ orderIndex ]: { field: col.key, value: newSort }}
            );
        }
        
        newColumns = Object.assign(
            [],
            columns,
            {
                [ i ]: {
                    ...col,
                    sort: newSort,
                },
            }
        );

        setSort(sortOrder);
        setColumns(newColumns);
    },
});

const boolChangeHandler = (filters, setFilters) => (col, index) => e => {

    const filter = filters[ index ];

    setFilters(Object.assign(
        [],
        filters,
        {
            [ index ]: {
                ...filter,
                value: e.target.value,
            },
        }
    ));
};

const numberChangeHandler = (filters, setFilters) => (col, index) => e => {

    const filter = filters[ index ];

    setFilters(Object.assign(
        [],
        filters,
        {
            [ index ]: {
                ...filter,
                value: e.target.value,
            },
        }
    ));
};

const textValueHandler = (timeoutIds, filters, textBuffs, setFilters, setTextBuffs) =>
(col, index) => {

    const filter = filters[ index ];

    return e => {
    
        setTextBuffs({
            ...textBuffs,
            [ col.key ]: e.target.value,
        });

        setFilters(Object.assign(
            [],
            filters,
            {
                [ index ]: {
                    ...filter,
                    value: e.target.value,
                },
            }
        ));
    };
};

const dateChangeHandlers = (filters, setFilters) => ({

    onDateFromChange: (col, index) => e => {

		const filter = filters[ index ];

		setFilters(Object.assign(
			[],
			filters,
			{
				[ index ]: {
					...filter,
					value: [ e.target.value, filter.value[ 1 ]],
				},
			}
		));
	},
	onDateToChange: (col, index) => e => {

		const filter = filters[ index ];

		setFilters(Object.assign(
			[],
			filters,
			{
				[ index ]: {
					...filter,
					value: [ filter.value[ 0 ], e.target.value ],
				},
			}
		));
	},
});

const multiSelectHandler = (filters, setFilters) => (col, index) => e => {

    const { target } = e;
    const filter = filters[ index ];
    const value = Array.from(target.selectedOptions, o => o.value);

    setFilters(Object.assign(
        [],
        filters,
        {
            [ index ]: {
                ...filter,
                value,
            },
        }
    ));
};

const BoolForm = ({ col, feed, setEditingCell, updateFeed }) => {

    const clickAwayRef = useRef(null);

    useClickAway(clickAwayRef, e => {
        setEditingCell([]);
    });

    return (
        <select
            className={ s.binMenu }
            ref={ clickAwayRef }
            onChange={
                (e) => {
                    const v = e.target.value === '1' ? true : false;
                    setEditingCell([]);
                    updateFeed(v);
                }
            }
            value={ feed[ col.key ] ? 1 : 0 }
        >
            <option value={ 0 }>No</option>
            <option value={ 1 }>Yes</option>
        </select>
    );
};

export default connect(
    (state, props) => ({
        user: $users.selectors.getLoggedInUser(state, props),
    }),
    {
        updateFeed: $feeds.actions.updateFeedRequest,
    }
)(
    ({
        user,
        feeds,
        columns,
        sort,
        filters,
        filterOptions,
        filterCtls,
        setColumns,
        setSort,
        setFilters,
        setFilterOptions,
        setFilterCtls,
        updateFeeds,
        updateFeed,
    }) => {

        let colTimeout;

        const { onColumnClick, onColumnDoubleClick } = columnClickHandlers(
            columns, sort, setColumns, setSort
        );

        const onBoolChange = boolChangeHandler(filters, setFilters);
        const onNumberChange = numberChangeHandler(filters, setFilters);

        const [ textBuffs, setTextBuffs ] = useState({
            id: '',
            title: '',
            url: '',
        });

        const onTextValueChange = textValueHandler(timeoutIds, filters, textBuffs, setFilters, setTextBuffs);
        const onMultiSelectChange = multiSelectHandler(filters, setFilters);
        const clearFilter = makeClearFilter(filters, setFilters, textBuffs, setTextBuffs);

        const { onDateFromChange, onDateToChange } = dateChangeHandlers(filters, setFilters);

        const [ inputTypeLastPolledFrom, setInputTypeLastPolledFrom ] = useState('text');
        const [ inputTypeLastPolledTo, setInputTypeLastPolledTo ] = useState('text');
        const [ inputTypeLastSuccessfulPollFrom, setInputTypeLastSuccessfulPollFrom ] = useState('text');
        const [ inputTypeLastSuccessfulPollTo, setInputTypeLastSuccessfulPollTo ] = useState('text');
        const [ inputTypeLastErrorDateFrom, setInputTypeLastErrorDateFrom ] = useState('text');
        const [ inputTypeLastErrorDateTo, setInputTypeLastErrorDateTo ] = useState('text');
        const [ editingCell, setEditingCell ] = useState([]);

        const inputTypes = {
            lastPolled: {
                from: {
                    value: inputTypeLastPolledFrom,
                    set: setInputTypeLastPolledFrom,
                    ref: useRef(null),
                },
                to: {
                    value: inputTypeLastPolledTo,
                    set: setInputTypeLastPolledTo,
                    ref: useRef(null),
                },
            },
            lastSuccessfulPoll: {
                from: {
                    value: inputTypeLastSuccessfulPollFrom,
                    set: setInputTypeLastSuccessfulPollFrom,
                    ref: useRef(null),
                },
                to: {
                    value: inputTypeLastSuccessfulPollTo,
                    set: setInputTypeLastSuccessfulPollTo,
                    ref: useRef(null),
                },
            },
            lastErrorDate: {
                from: {
                    value: inputTypeLastErrorDateFrom,
                    set: setInputTypeLastErrorDateFrom,
                    ref: useRef(null),
                },
                to: {
                    value: inputTypeLastErrorDateTo,
                    set: setInputTypeLastErrorDateTo,
                    ref: useRef(null),
                },
            },
        };

        return (
            <table className={ s.table }>
                <thead className={ s.head }>
                    <tr>{
                        columns.reduce(
                            (cols, col, i) => {

                                const filter = filters[ i ];

                                if (col.visible) {
                                    return [
                                        ...cols,
                                        <th
                                            key={ col.key }
                                            className={ sx(`${ col.key }Col`) }
                                        >
                                            <div
                                                className={ s.colLabelCont }
                                                onClick={
                                                    e => {
                                                        if (e.detail > 1) {
                                                            clearTimeout(colTimeout);
                                                            onColumnDoubleClick(i, e);

                                                        } else {
                                                            colTimeout = setTimeout(
                                                                () => {
                                                                    onColumnClick(i, e);
                                                                },
                                                                250
                                                            );
                                                        }
                                                    }
                                                }
                                            >
                                                <label className={ s.colLabel }>
                                                    { col.shortLabel || col.label }
                                                    <div className={ s.sortIcon }>
                                                    <FontAwesomeIcon
                                                        icon={
                                                            sortIcons[ col.sort.toString() ]
                                                        }
                                                    />
                                                </div>
                                                </label>
                                                
                                            </div>
                                            { filterCtls && 
                                                <div className={ s.colFilterCont }>
                                                    { filter &&
                                                        <div className={ s.formCont }>
                                                        { filter.type === 'list' && 
                                                            <select
                                                                name={ col.key }
                                                                size={
                                                                    filterOptions[ filter.key ].length > 5 ?
                                                                        5 :
                                                                        filterOptions[ filter.key ].length
                                                                }
                                                                multiple={ filter.multi }
                                                                onChange={ onMultiSelectChange(col, i) }
                                                                value={ filter.value }
                                                            >
                                                                <option hidden disabled value=''></option>
                                                                {
                                                                    filterOptions[ filter.key ].map(
                                                                        (o, i) => (
                                                                            <option key={ i } value={ o.value }>
                                                                                { o.label }
                                                                            </option>
                                                                        )
                                                                    )
                                                                }
                                                            </select>
                                                        }
                                                        { filter.type === 'text' &&
                                                            <input
                                                                type='text'
                                                                value={ textBuffs[ col.key ] }
                                                                onChange={ onTextValueChange(col, i) }
                                                            />
                                                        }
                                                        { filter.type === 'number' &&
                                                            <input
                                                                type='number'
                                                                value={ filter.value }
                                                                onChange={ onNumberChange(col, i) }
                                                                min={ 0 }
                                                            />
                                                        }
                                                        { filter.type === 'boolean' &&
                                                            <select
                                                                defaultValue=''
                                                                onChange={ onBoolChange(col, i) }
                                                                value={ filter.value }
                                                            >
                                                                <option hidden disabled value=''></option>
                                                                <option value={ 0 }>No</option>
                                                                <option value={ 1 }>Yes</option>
                                                            </select>
                                                        }
                                                        { filter.type === 'date' &&
                                                            <>
                                                                <div className={ s.dateRow }>
                                                                    <input
                                                                        type={ inputTypes[ col.key ].from.value }
                                                                        ref={ inputTypes[ col.key ].from.ref }
                                                                        placeholder='From'
                                                                        onChange={ onDateFromChange(col, i)  }
                                                                        value={ filter.value[ 0 ] }
                                                                        onClick={ e => {
                                                                            inputTypes[ col.key ].from.set('date');
                                                                            setTimeout(() => {
                                                                                inputTypes[ col.key ].from.ref.current.showPicker();
                                                                            }, 100);
                                                                        }}
                                                                        onBlur={ e => inputTypes[ col.key ].from.set('text') }
                                                                    />
                                                                </div>
                                                                <div className={ s.dateRow }>
                                                                    <input
                                                                        type={ inputTypes[ col.key ].to.value }
                                                                        ref={ inputTypes[ col.key ].to.ref }
                                                                        placeholder='To'
                                                                        onChange={ onDateToChange(col, i)  }
                                                                        value={ filter.value[ 1 ] }
                                                                        onClick={ e => {
                                                                            inputTypes[ col.key ].to.set('date');
                                                                            setTimeout(() => {
                                                                                inputTypes[ col.key ].to.ref.current.showPicker();
                                                                            }, 100);
                                                                        }}
                                                                        onBlur={ e => inputTypes[ col.key ].to.set('text') }
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            (
                                                                (filter.type === 'text' && filter.value) ||
                                                                (filter.type === 'boolean' && filter.value) ||
                                                                (filter.type === 'list' && filter.value.length) ||
                                                                (filter.type === 'date' && (filter.value[ 0 ] || filter.value[ 1 ]))
                                                            ) &&
                                                            <div className={ s.clear } onClick={ clearFilter(i) } >
                                                                × Clear
                                                            </div>
                                                        }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </th>
                                    ];
                                }
                                return cols;
                            },
                            []
                        )
                }</tr>
                </thead>
                <tbody>
                    { feeds.map((f, i) => {

                        const bg = f.publication.images.dark ? 'dark': 'light';
                        const imgUrl = `${ APP_BASE_URL }/imgs/logos/${ f.publication._id }_${ bg }_50x50.png`

                        return (
                            <tr key={ f._id }>
                                
                                { columns.reduce(
                                    (cols, col, j) => {

                                        if (!col.visible) {
                                            return cols;
                                        }

                                        const [ x, y ] = editingCell;
                                        const isEditing = x === i && y === j;

                                        const cellValue = typeof col.getValue === 'function' ?
                                            col.getValue(f) :
                                            f[ col.key ];

                                        if (col.key === '_id') {
                                            return [ ...cols, <td key={ j }>{ f._id }</td> ];

                                        } else if (col.key === 'publication') {
                                            return [
                                                ...cols,
                                                <td key={ j } className={ s.publicationTrCol }>
                                                    <div className={ s.imgCont }>
                                                        <img src={ imgUrl } />
                                                    </div>
                                                    <div>{ f.publication.name }</div>
                                                </td>
                                            ];
                                        }

                                        let cellContent;

                                        if (col.editable && isEditing) {
                                            if (col.filter.type === 'boolean') {
                                                cellContent = <BoolForm
                                                    col={ col }
                                                    feed={ f }
                                                    feeds={ feeds }
                                                    setEditingCell={ setEditingCell }
                                                    updateFeed={
                                                        v => {
                                                            updateFeeds(Object.assign(
                                                                [],
                                                                feeds,
                                                                {
                                                                    [ i ]: {
                                                                        ...f,
                                                                        [ col.key ]: v,
                                                                    },
                                                                }
                                                            ));
                                                            updateFeed({ feedId: f._id, updates: { [ col.key ]: v }});
                                                        }
                                                    }
                                                />;
                                            }
                                        } else {
                                            cellContent = (
                                                <>
                                                    <span>{ cellValue }</span>
                                                    {
                                                        col.editable && col.filter.type === 'boolean' &&
                                                        <span className={ s.edit }>
                                                            <FontAwesomeIcon
                                                                icon='fa-pen'
                                                            />
                                                        </span>
                                                    }
                                                </>
                                            );
                                        }

                                        return [
                                            ...cols,
                                            <td
                                                key={ j }
                                                className={
                                                    sx(
                                                        `${ col.key }Col`,
                                                        { editable: col.editable }
                                                    )
                                                }
                                                onClick={
                                                    () => {
                                                        if (col.editable && !isEditing) {
                                                            setEditingCell([ i, j ]);
                                                        }
                                                    }
                                                }
                                            >
                                                { cellContent }
                                            </td>
                                        ];
                                    },
                                    []
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
);
