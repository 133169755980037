import { createGQL } from '@lib/utils/graphql-api';
import config from '@nl/feeds/config.json';
import queries from '@nl/feeds/graphql/queries';
import feedStd from '@nl/feeds/graphql/fragments/feed-std.gqlf';
import feedPlus from '@nl/feeds/graphql/fragments/feed-plus.gqlf';

export const gql = {
    queries,
    fragments: {
        feedStd,
        feedPlus,
    },
};

const api = createGQL(config, gql);

export default api;
