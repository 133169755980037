import cn from 'classnames/bind';
import Compose from '@lib/utils/compose';
//import Modal from '@mods/modals';
import '@ui/styles/base.scss';
import s from './styles.scss';

const sx = cn.bind(s);

const App = ({ providers, children }) => (
    <Compose components={ providers }>
        <div className={ sx('root') }>
            { children }
        </div>
    </Compose>
);

export default App;
