import { combineReducers } from 'redux';
import { reducer as app } from '@state/app';
import { reducer as auth } from '@state/auth';
import { reducer as feeds } from '@state/feeds';
import { reducer as publications } from '@state/publications';
//import { reducer as poller } from '@state/poller';
//import { reducer as pollerFeeds } from '@state/pollerFeeds';
import { reducer as users } from '@state/users';
import { reducer as workspace } from '@mods/workspace/redux';
import { reducer as stories } from '@state/stories';
import { reducer as tags } from '@state/tags';
import { reducer as socketio } from '@state/socketio';

const reducers = {
    app,
    auth,
    feeds,
    publications,
    //poller,
    //pollerFeeds,
    users,
    workspace,
    stories,
    tags,
    socketio,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
