import { useEffect, useState } from 'react';
import { basicApi2 } from '@lib/utils/graphql-api';
import feedsApi, { gql } from '@state/feeds/api';

import s from './styles.scss';

export default ctx => {

    const [ feed, setFeed ] = useState(null);

    useEffect(
        () => {
            basicApi2(
                gql.queries.getFeed(),
                {
                    feedId: ctx.feedId,
                }
            ).then(res => {
                if (res.success) {
                    console.log(res.data.feed);
                    setFeed(res.data?.feed);
                }
            });
        },
        []
    );

    return (
        <div className={ s.root }>
            { feed && 
            <table className={ s.feedList }>
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td>{ feed._id }</td>
                    </tr>
                    <tr>
                        <td>URL</td>
                        <td>{ feed.url }</td>
                    </tr>
                    <tr>
                        <td>Title</td>
                        <td>{ feed.title }</td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>{ feed.description }</td>
                    </tr>
                    <tr>
                        <td>Publication ID</td>
                        <td>{ feed.publicationId }</td>
                    </tr>
                    <tr>
                        <td>Tag IDs</td>
                        <td>{ feed.tagIds }</td>
                    </tr>
                    <tr>
                        <td>Live</td>
                        <td>{ feed.live }</td>
                    </tr>
                    <tr>
                        <td>Aggregate</td>
                        <td>{ feed.aggregate }</td>
                    </tr>
                    <tr>
                        <td>Fetch Errors</td>
                        <td>{ feed.fetchErrors }</td>
                    </tr>
                    <tr>
                        <td>Last Error</td>
                        <td>{ feed.lastError }</td>
                    </tr>
                    <tr>
                        <td>Last Error Date</td>
                        <td>{ feed.lastErrorDate }</td>
                    </tr>
                    <tr>
                        <td>Last Polled</td>
                        <td>{ feed.lastPolled }</td>
                    </tr>
                    <tr>
                        <td>Last Successful Poll</td>
                        <td>{ feed.lastSuccessfulPoll }</td>
                    </tr>
                </tbody>
            </table>}
        </div>
    );
};
