import React, { useState, useRef } from 'react';
import { useFloating, offset, size, shift, arrow } from '@floating-ui/react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames/bind';

import { useClickAway } from '@ui/react-utils';

import s from './styles.scss';

const sx = cn.bind(s);

export default ({
	data,
	optionEl: OptionEl,
	classNames = {},
	onChange = () => {}
}) => {

	const clickAwayRef = useRef(null);
	const arrowRef = useRef(null);

	useClickAway(clickAwayRef, () => setMenuOpen(false));

	const {
		x,
		y,
		refs,
		floatingStyles,
		strategy,
		update,
		middlewareData: {
			arrow: {
				x: arrowX,
				y: arrowY,
			} = {}
		},
	} = useFloating({
		placement: 'bottom-start',
		middleware: [
			offset(8),
			shift(),
			arrow({ element: arrowRef }),
			size({
				apply: (ctx) => {
					const { availableWidth, reference, elements } = ctx;
					Object.assign(elements.floating.style, {
						minWidth: `${ elements.reference.offsetWidth }px`,
					});
				},
			}),
		],
	});

	let { valueContOuter, valueCont, menu, menuItem } = classNames;

	const _classNames = Object.entries({
		valueContOuter,
		valueCont,
		menu,
		menuItem,
	}).reduce(
		(cns, [ label, value ]) => {

			if (value) {
				if (Array.isArray(value)) {
					return {
						...cns,
						[ label ]: [ sx(label), ...value ],
					};
				} else if (typeof value === 'object' ) {
					if (value.overwrite) {
						return { ...cns, [ label ]: value.value };
					} else {
						if (Array.isArray(value.value)) {
							return { ...cns, [ label ]: [ sx(label), ...value.value ] };
						} else {
							return { ...cns, [ label ]: [ sx(label), value.value ] };
						}
					}
				} else {
					return { ...cns, [ label ]: [ sx(label), value ]};
				}
			}

			return { ...cns, [ label ]: sx(label) };
		},
		{}
	);

	const [ menuOpen, setMenuOpen ] = useState(false);

	return (
		<div ref={ refs.setReference }>
			<div className={ s.cont } ref={ clickAwayRef }>
				<div className={ s.label } onClick={ () => setMenuOpen(!menuOpen) }>
					{/*<div className={ s.iconCont }>
						<FontAwesomeIcon icon='eye' />
					</div>*/}
					<div className={ s.text }>
						Columns
					</div>
					<div className={ s.iconCont }>
						<FontAwesomeIcon icon='caret-down' />
					</div>
				</div>
				 {
					menuOpen &&
					<div
						className={ cn(_classNames.menu) }
						ref={ refs.setFloating }
						style={ floatingStyles }
					>
						<div
							ref={ arrowRef }
							className={ s.arrow }
							style={{
								position: strategy,
								top: arrowY ?? -5,
								left: arrowX ?? 0,
							}}
						/>
						{ data.map((d, i) =>
							<OptionEl
								key={ i }
								data={ d }
								onClick={ () => onChange(d, i) }
							/>
						)}
					</div>
				}
			</div>
		</div>
	);
};
