import { connect } from 'react-redux';
import cn from 'classnames/bind';
import { selectors as feedSels, actions as feedActs } from '@state/feeds';

import c from '@ui/components/tile/styles.scss';

const cx = cn.bind(c);

export default connect(
	(state, props) => ({
		feeds: feedSels.getFeedsByPublicationId(state, props),
	}),
	{}
)(
	({ feeds, setFeed, deleteFeed }) => (
		<ul className={ cx('list') }>{
			feeds.map(f =>
				<li key={ f._id } className={ cx('listItem') }>
					<span className={ cx('listItemContent') }>{ f.url }</span>
					<button
						className={ cx('btnView') }
						onClick={ () => setFeed({ feedId: f._id }) }
					>
						View
					</button>
					<button
						className={ cx('btnDelete') }
						onClick={ () => deleteFeed({ feedId: f._id, publicationId: f.publicationId }) }
					>×</button>
				</li>
			)
		}</ul>
	)
);
