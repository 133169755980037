import { createSelector } from '@reduxjs/toolkit';
import { includes, prop } from 'ramda';

import { basicOpts } from '@lib/utils/react';

const self = state => state.workspace;
const publications = state => state.publications;
const feeds = state => state.feeds;
const tags = state => state.tags;

const feedsMap = createSelector(feeds, prop('byId'));
const tagsMap = createSelector(tags, prop('byId'));

const publicationsMap = createSelector(publications, prop('byId'));

export const getPublicationId = createSelector(self, prop('publicationId'));

export const getPublication = createSelector(
    [ getPublicationId, publicationsMap ],
    prop
);

export const getFeedId = createSelector(self, prop('feedId'));

export const getFeed = createSelector(
    [ feedsMap, getFeedId ],
    (feedsMap, feedId) => feedsMap[ feedId ]
);

export const pubFeedsLoaded = ({ workspace }, { publication }) => includes(
    publication._id,
    workspace.loaded.publicationFeeds
);

export const getPublicationTagIds = createSelector(
    getPublication,
    publication => publication ? publication.tags : []
);

export const getFeedTagIds = createSelector(
    getFeed,
    feed => feed ? feed.tags : []
);

export const getPublicationTagOpts = createSelector(
    [ getPublicationTagIds, tagsMap ],
    (tagIds, tags) => tagIds && tagIds.length  ?
        basicOpts(tagIds, tags, 'name') :
        []
);

export const getFeedTagOpts = createSelector(
    [ getFeedTagIds, tagsMap ],
    (tagIds, tags) => tagIds && tagIds.length ?
        basicOpts(tagIds, tags, 'name') :
        []
);

export default {
    getPublicationId,
    getPublication,
    getFeedId,
    getPublication,
    getFeed,
    pubFeedsLoaded,
    getPublicationTagIds,
    getFeedTagIds,
    getPublicationTagOpts,
    getFeedTagOpts,
};
