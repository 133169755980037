import { connect } from 'react-redux';
import Select from 'react-select';
import cn from 'classnames/bind';

import Tile from '@ui/components/tile';
import { bindThis } from '@lib/utils/react';
import { selectors as workSels } from '@mods/workspace/redux';

import FeedsCtlForm from '../feeds-ctl-form';

import s from '../publications/styles.scss';
import c from '@ui/components/tile/styles.scss';

const sx = cn.bind(s);
const cx = cn.bind(c);

export default connect(
	(state, props) => ({
		feedId: workSels.getFeedId(state, props),
	}),
	{},
)(
	({ feedId }) => (
		<Tile
			title='Feed'
			className={ s.feedCell }
			flexGrow={ 3 }
		>
			{ !feedId && <div className={ s.none }>No Feed Selected</div> }
			{ feedId && <FeedsCtlForm key={ feedId } /> }
		</Tile>
	)
);
