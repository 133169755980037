import { connect } from 'react-redux';

import Private from '@ui/components/private';
import AppLayout from '@ui/layouts/app';
import Header from './ui/header';
import Workspace from './ui/workspace';

export default connect(
    (state, props) => ({
    }),
    {
    }
)(
    ({ props }) => {
        return (
            <Private>
                <AppLayout
                    header={() => (
                        <Header { ...props } />
                    )}
                    workspace={() => (
                        <Workspace { ...props } />
                    )}
                />
            </Private>
        );
    }
);
