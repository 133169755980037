import * as r from 'ramda';
import { createReducer } from '@lib/utils/redux';
import config from './config.json';
import actions from './actions';

const reducers = {
    loginSuccess: (state, action) => {
        const { payload } = action;
        const { user, accessToken } = payload;

        state.loggedInUserId = user._id;
        state.accessToken = accessToken;
        state.loginDetermined = true;
    },
    refreshRequest: (state, action) => {
        state.refreshPending = true;
    },
    refreshSuccess: (state, { payload }) => {

        const { accesstoken } = payload;

        state.loggedInUserId = JSON.parse(atob(split('.', accessToken)[ 1 ])).user._id;
        state.accessToken = accessToken;
        state.refreshPending = false;
        state.loginDetermined = true;
    },
    refreshFailure: (state, action) => {
        state.refreshPending = false;
        state.loginDetermined = true;
    },
    logoutSuccess: (state, action) => {
        state.loggedInUserId = null;
        state.accessToken = null;
    },
};

const reducer = createReducer(config, actions, reducers);

export default reducer;
