import { createActions, createReducer } from '@lib/utils/redux';
import config from './config.json';
import reducers from './reducers';
//import saga from './sagas';
import selectors from './selectors';

const actions = createActions(config);
const reducer = createReducer(config, actions, reducers);

export { actions };
//export { saga };
export { selectors };
export { reducer };

export default {
    actions,
    selectors,
    reducer,
};
