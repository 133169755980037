'use strict'

import React, { useState, useEffect } from 'react';
import cn from 'classnames/bind';

import s from './styles.scss';

const sx = cn.bind(s);

export default ({ data, onClick }) => {

	return (
		<div
			className={ s.columnItem }
			onClick={ onClick }
		>
			<input
				type='checkbox'
				className={ s.columnItemInput }
				checked={ data.visible }
				readOnly
			/>
			<label className={ s.columnItemLabel }>
				{ data.label }
			</label>
		</div>
	);
};
