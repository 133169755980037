import { createGQL } from '@lib/utils/graphql-api';
import config from '@nl/publications/config.json';
import queries from '@nl/publications/graphql/queries';
import pubStd from '@nl/publications/graphql/fragments/pub-std.gqlf';

export const gql = {
    queries,
    fragments: {
        pubStd,
    },
};

const api = createGQL(config, gql);

export default api;
