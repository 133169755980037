import Link from '@ui/components/link';
import LinkBtn from '@ui/components/header/link-btn';
import ls from '@ui/components/header/link-btn/styles.scss';

export default ctx => (
    <>
        <LinkBtn>
            <Link href='/dashboard' className={ ls.link }>
                Dashboard
            </Link>
        </LinkBtn>
        <LinkBtn>
            <Link href='/poller' className={ ls.link }>
                Poller
            </Link>
        </LinkBtn>
        <LinkBtn>
            <Link href='/feeds' className={ ls.link }>
                Feeds
            </Link>
        </LinkBtn>
    </>
);
