import {
	compose as c, concat, groupBy, has,	nthArg,
	prop, map, mapObjIndexed, mergeWith, uniq, 
} from 'ramda';

import { createReducer } from '@lib/utils/redux';
import config from '@nl/publications/config.json';
import actions from './actions';

import { actions as app } from '@state/app';

const reducerImports = {
    app,
};

const reducers = {
	byId: {
        createPublicationSuccess: (state, action) => {
            const publication = action.payload.publication;
            state[ publication._id ] = publication;
        },
        getPublicationBacklogSuccess: (state, action) => {
            const publications = action.payload.publications;
            publications.forEach(publication => {
                state[ publication._id ] = publication;
            });
        },
        updatePublicationSuccess: (state, action) => {
            const publication = action.payload.publication;
            state[ publication._id ] = publication;
        },
        deletePublicationSuccess: (state, action) => {
            const publication = action.payload.publication;
            delete state[ publication._id ];
        },
        uploadImageSuccess: (state, action) => {
            const publication = action.payload.publication;
            state[ publication._id ] = publication;
        },
        submitImageUrlSuccess: (state, action) => {
            const publication = action.payload.publication;
            state[ publication._id ] = publication;
        },
        invertForOtherSuccess: (state, action) => {
            const publication = action.payload.publication;
            state[ publication._id ] = publication;
        },
	},
	allIds: {
        createPublicationSuccess: (state, action) => {
            const publication = action.payload.publication;
            state.push(publication._id);
        },
        getPublicationBacklogSuccess: (state, action) => {
            const publications = action.payload.publications;
            publications.forEach(publication => {
                if (!state.includes(publication._id)) {
                    state.push(publication._id);
                }
            });
        },
        deletePublicationSuccess: (state, action) => {
            const publication = action.payload.publication;
            state.splice(state.findIndex(id => publication._id === id), 1);
        },
	},
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export default reducer;
