import { useState, useEffect } from 'react';
import SimpleSelect from '@ui/components/simple-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames/bind';

import s from './styles.scss';

const sx = cn.bind(s);

const pageSizes = [ 15, 25, 50, 100 ]
const pageSizeOpts = pageSizes.map(v => ({ label: v, value: v }));
const pageSizeOptsByVal = pageSizes.reduce(
	(byVal, v) => {
		return {
			...byVal,
			[ v.toString() ]: { label: v, value: v }
		};
	},
	{}
);

export default ({ config }) => {

	const {
		page,
		pageSize,
		total,
		totalPages,
        numItems,
		adjacent,
		boundWidth,
		boundReach,
		separator,
		onPageSizeChange,
		onPageChange,
		prevPage,
		nextPage,
	} = config;

    const selectionStart = (page - 1) * pageSize + 1;
    const selectionEnd = selectionStart + numItems - 1;

	const allPages = Array.from({ length: totalPages }, (_, i) => i + 1);

	const makePages = () => {

		if (allPages.length <= boundWidth) {
			return allPages;
		}

		const leftEdge = allPages.slice(0, boundWidth);
		const leftReach = allPages.slice(0, boundReach);
		const rightEdge = allPages.slice(-1 * boundWidth);
		const rightReach = allPages.slice(-1 * boundReach);
		const pageAdjacent = allPages.slice(page - 1 - adjacent, page + adjacent);

		let toInclude = [ ...leftEdge, ...pageAdjacent, ...rightEdge ];

		if (leftReach.includes(page + 1)) {
			toInclude = [ ...toInclude, ...leftReach ];
		}

		if (rightReach.includes(page - 1)) {
			toInclude = [ ...toInclude, ...rightReach ];
		}

		const visiblePages = [ ...new Set(toInclude) ].sort((a, b) => (a - b));

		return visiblePages.reduce(
			(all, curr) => {

				const prev = all.at(-1);

				if (Number.isFinite(prev) && curr !== 1 + prev) {
					return [ ...all, separator, curr ];
				}

				return [ ...all, curr ];
			},
			[]
		);

	};

	const displayPages = makePages();

	return (
		<div className={ s.statusBar }>
			<div className={ s.info }>
				<div className={ s.pageSize }>
					<div className={ s.formLabel }>Rows per page:</div>
					<SimpleSelect
						value={ pageSizeOptsByVal[ pageSize.toString() ] }
						options={ pageSizeOpts }
						onChange={ onPageSizeChange }
					/>
				</div>
				<div className={ s.showing }>
					<div className={ s.formLabel }>{
						`Showing ${ selectionStart } to ${ selectionEnd } of ${ total }`
					}</div>
				</div>
			</div>
			<div className={ s.pagination }>
				<div className={ sx('prevBtn', 'btn') } onClick={ prevPage }>
					<FontAwesomeIcon icon='chevron-left' />
				</div>
				{ 
					allPages.map((n, i) => (
						<div
							key={ i }
							className={
								sx('numBtn', 'btn', { active: page === n })
							}
							onClick={ e => onPageChange(n, e) }
						>
							{ n }
						</div>
					))
				}
				<div className={ sx('nextBtn', 'btn') } onClick={ nextPage }>
					<FontAwesomeIcon icon='chevron-right' />
				</div>
			</div>
		</div>
	);
};
