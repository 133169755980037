import getSocketio from '@lib/socketio';
import cn from 'classnames/bind';
import Workspace from '@ui/components/workspace';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import $socketio from '@state/socketio';
import Worker from '../worker';

import s from './styles.scss';

const Workers = ({ children }) => (
    <div className={ s.workers }>{ children }</div>
);

export default  connect(
    (state, props) => ({
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
    }),
    {
    }
)(
    ({ sockets }) => {

        let socket, socketio;

        useEffect(
            () => {

                socketio = getSocketio();

                if (socketio) {

                    socket = socketio.getSocket('/poller');

                    if (socket) {
                        socket.on('workers', workers => {
                            setWorkers(workers);
                        });
                        socket.emit('workersRequest');
                    }
                }

                return () => {
                    if (socket) {
                        socket.off('workers');
                    }
                };
            },
            [ sockets ]
        );

        const [ workers, setWorkers ] = useState([]);

        return (
            <Workspace>
                <Workers>
                    { workers && workers.map(w => <Worker key={ w.id } { ...w } />)}
                </Workers>
            </Workspace>
        );
    }
);
