import PUBLICATION_STD_FRAG from '@nl/publications/graphql/fragments/pub-std.gqlf';

const PUBLICATION_STD = '...pubStd';
const PUBLICATIONS_PAGED = `
    total
    publications {
        _id
        name
        url
        description
        tags
        live
        enabled
        hasAggregate
        images {
            light
            dark
        }
    }
`;
const createPublication = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
mutation CreatePublication($input: PublicationInput!) {
    publication: createPublication(input: $input) {
        ${ fields }
    }
}
${ fragments }
`;

const updatePublication = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
mutation UpdatePublication($publicationId: ID!, $updates: UpdatePublicationInput!) {
    publication: updatePublication(publicationId: $publicationId, updates: $updates) {
        ${ fields }
    }
}
${ fragments }
`;

const deletePublication = (fields = '_id', fragments = '') => `
mutation DeletePublication($publicationId: ID!) {
    publication: deletePublication(publicationId: $publicationId) {
        ${ fields }
    }
}
${ fragments }
`;

const getPublication = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
query GetPublication {
    publication: getPublication($publicationId: ID!) {
        ${ fields }
    }
}
${ fragments }
`;

const getPublicationBacklog = (fields = PUBLICATIONS_PAGED, fragments = PUBLICATION_STD_FRAG) => `
query GetPublicationBacklog($pageOpts: PublicationPageOptsInput) {
    results: getPublicationBacklog(pageOpts: $pageOpts) {
        ${ fields }
    }
}
`;

const getPublicationsByFeedIds = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
query GetPublicationsByFeedIds($ids: [ ID ]!) {
    publications: getPublicationsByFeedIds(ids: $ids) {
        ${ fields }
    }
}
${ fragments }
`;

const invertForOther = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
mutation InvertForOther($publicationId: ID!, $lteGroup: String!) {
    publication: invertForOther(publicationId: $publicationId, lteGroup: $lteGroup) {
        ${ fields }
    }
}
${ fragments }
`;

const submitImageUrl = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
mutation SubmitImageUrl($publicationId: ID!, $url: String!, $lteGroup: String!) {
    publication: submitImageUrl(publicationId: $publicationId, url: $url, lteGroup: $lteGroup) {
        ${ fields }
    }
}
${ fragments }
`;


const uploadImage = (fields = PUBLICATION_STD, fragments = PUBLICATION_STD_FRAG) => `
mutation UploadImage($publicationId: ID!, $file: Upload!, $lteGroup: String!) {
    publication: uploadImage(publicationId: $publicationId, file: $file, lteGroup: $lteGroup) {
        ${ fields }
    }
}
${ fragments }
`;

export default {
    createPublication,
    updatePublication,
    deletePublication,
    getPublication,
    getPublicationBacklog,
    getPublicationsByFeedIds,
    invertForOther,
    submitImageUrl,
    uploadImage,
};
