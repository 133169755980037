import axios from 'axios';
import * as r from 'ramda';
import { APP_BASE_URL } from '@src/env';

const url = new URL('graphql', APP_BASE_URL);

export const basicApi = async (query, fragments, variables) => {

    const res = await axios({
        url,
        method: 'POST',
        data: {
            query: `
                ${ query }
                ${ fragments }
            `,
            variables,
        },
    });

    return res.data;
};

export const basicApi2 = async (query, variables) => {

    const res = await axios({
        url,
        method: 'POST',
        data: {
            query,
            variables,
        },
    });

    return res.data;
};

const makeGraphqlApiClient = (getVariables, query, fragments) => async payload => {

    const variables = getVariables(payload);

    return basicApi(query, fragments, variables);
};

const makeGraphqlApiClient2 = (getVariables, _query, _fragments) =>
    async (payload, fields, frags) => {

        let query, fragments;
        const variables = getVariables(payload);

        if (typeof frags === 'string') {
            fragments = _fragments[ frags ];

        } else if (Array.isArray(frags)) {
            fragments = r.compose(
                r.join("\n"),
                r.values,
                r.pick(frags)
            )(fragments);
        }

        query = _query(fields, fragments);

        return basicApi2(query, variables);
    };

const makeGraphqlUploadApiClient = (getVariables, query, fragments) =>

    async payload => {

        const variables = getVariables(payload);
        const { file } = variables;

        const formData = new FormData();

		formData.append('operations', JSON.stringify({
			query: `
				${ query }
				${ fragments }
			`,
			variables: {
				...variables,
				file: null,
			}
		}));

		formData.append('map', JSON.stringify({ 'file': [ 'variables.file' ] }));
		formData.append('file', file);

        const res = await axios({
            url,
            method: 'POST',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return res.data;
    };

export const createGQL = (config, { queries, fragments }) => r.compose(
    r.reduce(
        (acc, [ name, conf ]) => {
            const makeApiFn = conf.api === 'graphqlUpload' ?
                makeGraphqlUploadApiClient :
                makeGraphqlApiClient2;

            const client = makeApiFn(
                r.pick(conf.payloadKeys[ 0 ]),
                queries[ name ],
                fragments
            );

            return r.assoc(
                name,
                client,
                acc
            );
        },
        {}
    ),
    r.filter(
        ([ name, conf ]) => (
            r.propEq('api', 'graphql', conf) ||
            r.propEq('api', 'graphqlUpload', conf)
        )
    ),
    r.toPairs,
    r.prop('async'),
    r.prop('actions'),
)(config);

export default (config, { queries, fragments: _fragments }) => r.compose(
    r.reduce(
        (acc, [ name, conf ]) => {
            
            const fragsArr = conf.plugins?.graphql?.fragments || [];
            
            const fragments = _fragments ? r.compose(
                r.defaultTo(''),
                r.join("\n"),
                r.values,
                r.pick(fragsArr),
            )(_fragments) : '';

            const payloadKeys = conf.payloadKeys[ 0 ];

            const makeApiFn = conf.api === 'graphqlUpload' ?
                makeGraphqlUploadApiClient :
                makeGraphqlApiClient;

            const client = makeApiFn(
                r.pick(payloadKeys),
                queries[ name ],
                fragments
            );

            return r.assoc(
                name,
                client,
                acc
            );
        },
        {}
    ),
    r.filter(
        ([ name, conf ]) => (
            r.propEq('api', 'graphql', conf) ||
            r.propEq('api', 'graphqlUpload', conf)
        )
    ),
    r.toPairs,
    r.prop('async'),
    r.prop('actions'),
)(config);
