import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { getHistory } from '@lib/history-manager';
import reducer from '@state/reducer';
import sagas from '@state/sagas';
import logger from '@lib/middleware/logger';
import socketio from '@lib/socketio';
import $auth from '@state/auth';

const sagaMiddleware = createSagaMiddleware();
const [ thunk, immutableStateInvariant ] = getDefaultMiddleware();

let _store;

const getStore = () => {

    if (_store) {
        return _store;
    }

    let middleware = [ thunk ];

    if (process.env.NODE_ENV !== 'production') {
        middleware = [
            ...middleware,
            immutableStateInvariant,
            logger,
        ];
    }

    middleware = [
        ...middleware,
        sagaMiddleware,
    ];

    _store = configureStore({
        reducer,
        middleware,
        devTools: process.env.NODE_ENV !== 'production',
    });

    //if (process.env.NODE_ENV === 'development') {

        const history = getHistory();

        if (history.location.pathname !== '/') {
            const loginStateStr = localStorage.getItem('loginState');
            const loginState = JSON.parse(loginStateStr);

            if (loginState && loginState.accessToken && loginState.user) {
                socketio(loginState.user._id);
                const action = $auth.actions.loginSuccess(loginState);
                _store.dispatch(action);
            }
            _store.subscribe(() => {
                window.zxcv = _store.getState();
            });
        }
    //}

    sagaMiddleware.run(sagas);

    return _store;
};

export default ({ children }) => {

    const store = getStore();

    return (
        <Provider store={ store }>
            { children }
        </Provider>
    );
};
