import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindThis } from '@lib/utils/react';
import cn from 'classnames/bind';

import { selectors as workSels, actions as workActs } from '@mods/workspace/redux';
import { selectors as feedSels, actions as feedActs } from '@state/feeds';
import { selectors as tagSels, actions as tagActs } from '@state/tags';

import s from '../publications/styles.scss';
import c from '@ui/components/tile/styles.scss';

const sx = cn.bind(s);
const cx = cn.bind(c);

class FeedsCtlForm extends React.Component {

	constructor(props) {

		super(props);

		const { feed } = props;

		let url = '',
			title = '',
			description = '',
			aggregate = false,
			tags = [];

		if (feed) {
			({ url, title, description, aggregate, tags } = feed);
		}

		this.state = {
			url,
			title,
			description,
			aggregate,
			tags,
		};

		bindThis(this, [
			'save',
			'toggleLive',
			'onChangeUrl',
			'onChangeTitle',
			'onChangeDescription',
			'onChangeAggregate',
			'onChangeTags',
		]);
	}

	onChangeUrl(e) {
		this.setState({ url: e.target.value });
	}

	onChangeTitle(e) {
		this.setState({ title: e.target.value });
	}

	onChangeDescription(e) {
		this.setState({ description: e.target.value });
	}

	onChangeAggregate(e) {
		this.setState({ aggregate: !this.state.aggregate });
	}

	onChangeTags(tags) {
		this.setState({ tags: tags.map(t =>t.value) });
	}

	async save() {

		const { feedId, updateFeed } = this.props;
		const { url, title, description, aggregate, tags } = this.state;
		const { errors } = await updateFeed({
			feedId,
			updates: { url, title, description, aggregate, tags }
		});
	}

	async toggleLive(e) {
		const { feed, feedId, updateFeed } = this.props;
		const { live } = feed;
		const { errors } = await updateFeed({
			feedId,
			updates: { live: !live }
		});
	}

	render() {

		const {
			feed,
			feedId,
			getFeed,
			tagOpts,
			feedTagOpts,
		} = this.props;

		const {
			url,
			title,
			description,
			aggregate,
		} = this.state;

		if (feedId && !feed) {
			getFeed({ feedId });
		}

		return (
			<div>
				<div className={ cx('btnRow') }>
					<button
						className={ cx('btnSave') }
						onClick={ this.save }
					>
						<i className='fa fa-plus' />
						&nbsp;Save
					</button>
					<button
						className={ cx('btnLive') }
						onClick={ this.toggleLive }
					>
						<span className={ cx({ live: feed.live }) }>&bull;</span>
						&nbsp;Live
					</button>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>URL</label>
					<input
						type='text'
						value={ url }
						className={ c.fieldInput }
						onChange={ this.onChangeUrl }
					/>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Title</label>
					<input
						type='text'
						value={ title }
						className={ c.fieldInput }
						onChange={ this.onChangeTitle }
					/>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Description</label>
					<textarea
						type='text'
						value={ description }
						className={ c.fieldTextarea }
						onChange={ this.onChangeDescription }
					/>
				</div>
				<div className={ cx('fieldInlineRow') }>
					<label className={ c.fieldInlineLabel }>Aggregate</label>
					<input
						type='checkbox'
						onChange={ this.onChangeAggregate }
						checked={ aggregate }
					/> 
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Tags</label>
					<Select
						closeMenuOnSelect={ false }
						isMulti
						defaultValue={ feedTagOpts }
						options={ tagOpts }
						onChange={ this.onChangeTags }
					/>
				</div>
			</div>
		);
	}
}

export default connect(
	(state, props) => ({
		feed: workSels.getFeed(state, props),
		feedId: workSels.getFeedId(state, props),
		tagOpts: tagSels.makeGetTagOpts()(state, props),
		feedTagOpts: workSels.getFeedTagOpts(state, props),
	}),
	{
		updateFeed: feedActs.updateFeedRequest,
	},
)(FeedsCtlForm);
