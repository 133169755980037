import cn from 'classnames/bind';
import { connect } from 'react-redux';
import workspace from '@mods/workspace/redux';

import Tile from '@ui/components/tile';
import PubsCtlForm from '../pubs-ctl-form';

import s from './styles.scss';

const sx = cn.bind(s);

export default connect(
	(state, props) => ({
        publication: workspace.selectors.getPublication(state, props),
    }),
    {
    }
)(
    ({ publication }) => (
        <Tile
            title='Publication'
            className={ sx('publicationCell') }
            flexGrow={ 3 }
        >
            { publication && <PubsCtlForm key={ publication._id } publication={ publication }/> }
            { !publication && <div className={ s.none }>No Publication Selected</div> }
        </Tile>
    )
);
