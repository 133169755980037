import { connect } from 'react-redux';
import { compose as c, concat, join, pair } from 'ramda';
import publications from '@state/publications';

export default connect(
	(state, props) => ({
		src: publications.selectors.getLogoSrc(state, props),
	})
)(
	({ src, time }) => c(
		src => <img src={ src } />,
		join('?'),
		pair(src),
	)(time)
);
