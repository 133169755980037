import Header from '@ui/components/header';
import MainHeader from '@ui/components/header/main';
import Nav from '@ui/components/header/nav';

export default ctx => {
    return (
        <MainHeader
            left={
                () => <Nav />
            }
        />
    );
};
