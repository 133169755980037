import { fork, takeLatest } from 'redux-saga/effects'
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import api from './api';

const createFeed = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.createFeed, acts.createFeed);

    yield takeLatest(acts.createFeed.request, workerSaga);
};

const getFeed = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getFeed, acts.getFeed);

    yield takeLatest(acts.getFeed.request, workerSaga);
};

const getFeeds = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getFeeds, acts.getFeeds);

    yield takeLatest(acts.getFeeds.request, workerSaga);
};

const getPublicationFeeds = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getPublicationFeeds, acts.getPublicationFeeds);

    yield takeLatest(acts.getPublicationFeeds.request, workerSaga);
};

const getFeedsByIds = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.getFeedsByIds, acts.getFeedsByIds);

    yield takeLatest(acts.getFeedsByIds.request, workerSaga);
};

const updateFeed = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.updateFeed, acts.updateFeed);

    yield takeLatest(acts.updateFeed.request, workerSaga);
};

const deleteFeed = function* () {

	const acts = groupActions(actions);
    const workerSaga = createAPISaga(api.deleteFeed, acts.deleteFeed);

    yield takeLatest(acts.deleteFeed.request, workerSaga);
};

export default function* () {

    const sagas = [
        createFeed,
        getFeed,
        getFeeds,
        getPublicationFeeds,
        getFeedsByIds,
        updateFeed,
        deleteFeed,
    ];

	for (let saga of sagas) {
		yield fork(saga);
	}
};
