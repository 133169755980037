import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ADMIN_PROTOCOL, ADMIN_HOST, ADMIN_PORT } from '@src/env';
import getSocketio from '@lib/socketio';
import axiosClient from '@lib/axios-client';
import cn from 'classnames/bind';
import socket from '@lib/socketio';
import Header from '@ui/components/header';
import MainHeader from '@ui/components/header/main';
import Nav from '@ui/components/header/nav';
import $socketio from '@state/socketio';
import s from './styles.scss';

const sx = cn.bind(s);

export default  connect(
    (state, props) => ({
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
    }),
    {
    }
)(
    ({ sockets }) => {

        let socket, socketio, ops;

        useEffect(
            () => {

                socketio = getSocketio();

                if (socketio) {

                    socket = socketio.getSocket('/poller');

                    if (socket) {
                        ops = {
                            start: () => socket.emit('start'),
                            stop: () => socket.emit('stop'),
                            status: () => socket.emit('status'),
                            flush: () => socket.emit('flush'),
                        };
                        socket.on('status', console.log);
                    }
                }

                return () => {
                    if (socket) {
                        socket.off('workers');
                    }
                };
            },
            [ sockets ]
        );

        return (
            <MainHeader
                left={ () => <Nav /> }
                center={
                    () => (
                        <div className={ sx('btnRow') }>
                            <button
                                className={ sx('btn') }
                                onClick={
                                    () => {
                                        ops && ops.start();
                                    }
                                }
                            >
                                Start
                            </button>
                            <button
                                className={ sx('btn') }
                                onClick={
                                    () => {
                                        ops && ops.stop();
                                    }
                                }
                            >
                                Stop
                            </button>
                            <button
                                className={ sx('btn') }
                                onClick={
                                    () => {
                                        ops && ops.status();
                                    }
                                }
                            >
                                Status
                            </button>
                            <button
                                className={ sx('btn') }
                                onClick={
                                    () => {
                                        ops && ops.flush();
                                    }
                                }
                            >
                                Flush
                            </button>
                        </div>
                    )
                }
            />
        );
    }
);
