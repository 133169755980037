import actions from './actions';
import reducer from './reducers';
import selectors from './selectors';

export { actions };
export { reducer };
export { selectors };

export default {
    actions,
    reducer,
    selectors,
};
