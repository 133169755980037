import { createSelector } from '@reduxjs/toolkit';
import {
	append, compose as c, concat, defaultTo, difference, equals, filter, flip,
    forEach, identity, includes, isNil, lensPath, map, not, nthArg, pick, prop,
    propEq, reduce, reject, toPairs, transduce, uniq, view,
} from 'ramda';

import { selectors as auth } from '@state/auth';
import { selectors as feeds } from '@state/feeds';
import { selectors as publications } from '@state/publications';
import stories from '@state/stories/selectors';

import { makeOptions, allOpts } from 'users/utils';
import { groupFeedIdsByPublicationId, groupFeedsByPublicationId } from 'feeds/utils'

const self = state => state.users;

export const getUserId = c(prop('userId'), nthArg(1));

export const getUserIds = createSelector(self, prop('allIds'));

export const getUsersMap = createSelector(self, prop('byId'));

export const getUsernameMap = createSelector(self, prop('idsByUsername'));

export const getPublicationId = c(prop('publicationId'), nthArg(1));

export const getPublicationIds = c(prop('publicationIds'), nthArg(1));

export const getFeedIdsByPublicationId = c(prop('feedIdsByPublicationId'), nthArg(1));

export const getPath = c(view(lensPath([ 'page', 'path' ])));

export const getLoggedInUserId = c(view(lensPath([ 'auth', 'loggedInUserId' ])));

export const getLoggedInUser = createSelector(
    [ getLoggedInUserId, getUsersMap ],
    prop
);

export const getUser = createSelector(
    [ getUserId, getUsersMap ],
    prop
);

export const getPageUser = createSelector(
    [ getPath, getUsernameMap, getUsersMap ],
    (path, usernameMap, usersMap) => {
        return path[ 0 ] === 'user' && path[ 1 ] ?
        usersMap[ usernameMap[ path[ 1 ]]] :
        undefined
    }
);

export const getUserUrl = createSelector(
    [
        getLoggedInUser,
    ],
    user => `/@${ user.username }`,
);

export const getUserSubscriptionIds = createSelector(
    [
        auth.getLoggedInUserId,
        getUsersMap,
    ],
    (userId, users) => c(
        defaultTo([]),
        prop('subscriptions'),
        prop(userId),
    )(users)
);

export const getSubscriptions = createSelector(
    [
        getLoggedInUser,
    ],
    prop('subscriptions'),

);

export const getSubsByPubIds = createSelector(
    [
        getSubscriptions,
    ],
    c(
        reduce(
            (byPubId, sub) => ({
                ...byPubId,
                [ sub.publicationId ]: sub,
            }),
            {}
        ),
    ),
);

export const getSubscribedPublicationIds = createSelector(
    [
        getSubscriptions,
    ],
    c(
        map(prop('publicationId')),
    ),
);

export const getSubscribedFeedIds = createSelector(
    [
        getSubscriptions,
    ],
    reduce(
        (feedIds, subObj) => concat(feedIds, prop('feedIds', subObj)),
        []
    ),
);

export const getSubscribedFeeds = createSelector(
    [
        getSubscribedFeedIds,
        feeds.getFeedsMap,
    ],
    pick,
);

export const getBookmarkIds = createSelector(
    [
        auth.getLoggedInUserId,
        getUsersMap,
    ],
    (userId, users) => c(
        defaultTo([]),
        prop('bookmarks'),
        prop(userId),
    )(users)
);

export const getBookmarks = createSelector(
    [
        getBookmarkIds,
        stories.getStoriesMap,
    ],
    pick
);

export const hasPublicationSubs = createSelector(
    [
        getPublicationId,
        getSubscribedPublicationIds,
    ],
    (publicationId, subscribedPublicationIds) =>
        subscribedPublicationIds.includes(publicationId),
);

export const hasPublicationSubOnly = createSelector(
    [
        getPublicationId,
        getSubscriptions,
    ],
    (publicationId, subscriptions) => {
        const sub = subscriptions.find(s => s.publicationId === publicationId);

        if (sub) {
            return sub.pick === false;
        }

        return false;
    }
);

export const getPublicationSubscription = createSelector(
    [
        getPublicationId,
        getSubscriptions,
    ],
    (publicationId, subscriptions) => subscriptions.find(
        s => s.publicationId === publicationId
    ),
);

export const getUserPublications = createSelector(
    [
        getSubscribedPublicationIds,
        publications.getPublicationsMap
    ],
    (publicationIds, publicationsMap) => c(
        reject(isNil),
        flip(pick)(publicationsMap),
    )(publicationIds)
);

export const makeGetUserPublications = () => createSelector(getUserPublications, identity);

export const getUserPublicationFeeds = createSelector(
    [ getPublicationId, getSubscribedFeeds ],
    (publicationId, feeds) => filter(propEq('publicationId', publicationId), feeds),
);

export const getUserCurrentView = createSelector(
    [ getLoggedInUser ],
    user => user ? prop('currentView', user) : []
);

export const getUserCurrentViewGroupIds = createSelector(
    [ getUserCurrentView ],
    transduce(
        filter(propEq('type', 'group')),
        (acc, curr) => append(prop('id',curr), acc),
        []
    )
);

export const getUserCurrentViewPublicationIds = createSelector(
    [ getUserCurrentView ],
    transduce(
        filter(propEq('type', 'publication')),
        (acc, curr) => append(prop('id',curr), acc),
        []
    )
);

export const getUserCurrentViewFeedIds = createSelector(
    [ getUserCurrentView ],
    transduce(
        filter(propEq('type', 'feed')),
        (acc, curr) => append(prop('id',curr), acc),
        []
    )
);

export const getUserFeedIdsByPublicationId = createSelector(
    [ getSubscriptions ],
    c(
        reduce(
            (byPubId, sub) => {
                return {
                    ...byPubId,
                    [ sub.publicationId ]: sub.feedIds,
                };
            },
            {}
        ),
    ),
);

export const getUserFeedsByPublicationId = createSelector(
    [ feeds.getFeedsMap, getUserFeedIdsByPublicationId ],
    (feeds, feedIdsByPubId) => c(
        reduce(
            (byPubId, [ publicationId, feedIds ]) => ({
                ...byPubId,
                [ publicationId ]: feedIds.map(id => feeds[ id ]),
            }),
            {}
        ),
        toPairs,
    )(feedIdsByPubId),
);

export const getStructuredFeedsAndPublications = createSelector(
    [
        getSubscribedPublicationIds,
        getUserFeedsByPublicationId,
        publications.getPublicationsMap,
    ],
    (
        userPublicationIds,
        userFeedsByPublicationId,
        publications
    ) => reduce(
        (byPubId, pubId) => ({
            ...byPubId,
            [ pubId ]: [
                publications[ pubId ],
                userFeedsByPublicationId[ pubId ],
            ]
        }),
        [],
        userPublicationIds
    ),
);

export const getSettings = createSelector(
    [ getLoggedInUser ],
    user => user?.settings || {}
);

export default {
    getUserId,
    getUserIds,
    getUsersMap,
    getUsernameMap,
    getPublicationId,
    getPublicationIds,
    getFeedIdsByPublicationId,
    getPath,
    getLoggedInUserId,
    getLoggedInUser,
    getUser,
    getPageUser,
    getUserUrl,
    getUserSubscriptionIds,
    getSubscriptions,
    getSubsByPubIds,
    getSubscribedPublicationIds,
    getSubscribedFeedIds,
    getSubscribedFeeds,
    getBookmarkIds,
    getBookmarks,
    getPublicationSubscription,
    getUserPublications,
    getUserPublicationFeeds,
    getUserCurrentView,
    getUserCurrentViewGroupIds,
    getUserCurrentViewPublicationIds,
    getUserCurrentViewFeedIds,
    getUserFeedIdsByPublicationId,
    getUserFeedsByPublicationId,
    getStructuredFeedsAndPublications,
    getSettings,
};
