import { useEffect } from 'react';
import { connect } from 'react-redux';

import auth from '@state/auth';

export default connect(
	(state, props) => ({
		isLoggedIn: auth.selectors.isLoggedIn(state),
        loginDetermined: auth.selectors.loginDetermined(state),
        isRefreshing: auth.selectors.isRefreshing(state),
	}),
	{
		refresh: auth.actions.refreshRequest,
        loginSuccess: auth.actions.loginSuccess,
	}
)(
	({
        loginDetermined,
        isRefreshing,
        refresh,
        loginSuccess,
        children
    }) => {
        useEffect(
            () => {
                if (!loginDetermined && !isRefreshing) {
                    refresh({ type: 'access' });
                }
            },
            [
                loginDetermined,
                isRefreshing,
            ]
        );

        return <>{ children }</>;
    }
);
