import { format } from 'date-fns';

const tf = v => v ? 'Yes' : 'No';

export default [
	{
		key: '_id',
		label: 'ID',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
	{
		key: 'name',
		label: 'Name',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
    {
		key: 'url',
		label: 'URL',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
    {
		key: 'live',
		label: 'Live',
		visible: true,
		sort: 0,
		getValue: p => tf(p.live),
		filter: {
			type: 'boolean',
			value: undefined,
		},
        editable: true,
	},
    {
		key: 'enabled',
		label: 'Enabled',
		visible: true,
		sort: 0,
		getValue: p => tf(p.enabled),
		filter: {
			type: 'boolean',
			value: undefined,
		},
        editable: true,
	},
    {
		key: 'hasAggregate',
		label: 'Has Aggregate',
		visible: true,
		sort: 0,
		getValue: p => tf(p.hasAggregate),
		filter: {
			type: 'boolean',
			value: undefined,
		},
        editable: true,
	},
    {
		key: 'description',
		label: 'Description',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
];
