import { isEmpty, not, } from 'ramda';
import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import cn from 'classnames/bind';

import { bindThis } from '@lib/utils/react';
import { selectors as workSels, actions as workActs } from '@mods/workspace/redux';
import { selectors as pubSels, actions as pubActs } from '@state/publications';
import { selectors as feedSels, actions as feedActs } from '@state/feeds';
import { selectors as tagSels, actions as tagActs } from '@state/tags';
//import users from 'users';
import Logo from '@ui/components/logo';
import Feeds from '../publication-feeds';

//import i from '@/views/styles/icomoon.css';
import s from './styles.scss';
import c from '@ui/components/tile/styles.scss';

const sx = cn.bind(s);
const cx = cn.bind(c);

class PubsCtlForm extends React.Component {

	constructor(props) {

		super(props);

		const { publication } = props;

		let url = '',
			logo = '',
			name = '',
			description = '',
			tags = [],
			hasAggregate = false,
			feeds = [],
			images = {};

		if (publication) {
			({ url, name, description, tags, hasAggregate, feeds, images } = publication);
		}

		this.state = {
			url,
			logo,
			name,
			description,
			tags,
			hasAggregate,
			feeds,
			images,
			newFeed: '',
			imageUrl: '',
			lteGroup: '',
			time: Date.now(),
		};

		bindThis(this, [
			'save',
			'toggleLive',
			'onChangeUrl',
			'onChangeName',
			'onChangeDescription',
			'onChangeTags',
			'onChangeAggregate',
			'onChangeNewFeed',
			'onNewFeedKeyPress',
			'addFeed',
			'onChangeImageUrl',
			'submitImageUrl',
			'invertForOther',
			'uploadImage',
		]);
	}

	onChangeUrl(e) {
		this.setState({ url: e.target.value });
	}

	onChangeName(e) {
		this.setState({ name: e.target.value });
	}

	onChangeDescription(e) {
		this.setState({ description: e.target.value });
	}
	
	onChangeTags(tags) {
		this.setState({ tags: tags.map(t =>t.value) });
	}

	onChangeAggregate(e) {
		this.setState({ hasAggregate: !this.state.hasAggregate });
	}

	onChangeNewFeed(e) {
		this.setState({ newFeed: e.target.value });
	}

	onNewFeedKeyPress(e) {

		if (e.key == 'Enter') {
			this.addFeed();
			this.setState({ newFeed: '' });
		}
		return false;
	}

	async addFeed() {
		const { newFeed } = this.state;
		const { createFeed, publication } = this.props;

		await createFeed({
			input: {
				publicationId: publication._id,
				url: newFeed
			}
		});
		this.setState({ newFeed: '' });
	}

	async save(e) {

		const { publication, updatePublication } = this.props;
		const { url, name, description, tags, hasAggregate } = this.state;

		const { errors } = await updatePublication({
			publicationId: publication._id,
			updates: { url, name, description, tags, hasAggregate }
		});
	}

	async toggleLive(e) {

		const { publication, updatePublication } = this.props;
		const { _id, live } = publication;

		const { errors } = await updatePublication({
			publicationId: _id,
			updates: { live: !live }
		});
	}

	onChangeImageUrl(e) {
		this.setState({ imageUrl: e.target.value });
	}

	async submitImageUrl(e) {

		const { publication, submitImageUrl } = this.props;
		const { imageUrl, lteGroup } = this.state;

		const { data, errors } = await submitImageUrl({
			publicationId: publication._id,
			url: imageUrl,
			lteGroup
		});

		this.setState({ time: Date.now() });
	}

	async uploadImage(payload) {

		const { uploadImage } = this.props;

		const res = await uploadImage(payload);

		setTimeout(() => this.setState({ time: Date.now() }), 1000);
	}

	async invertForOther(e) {

		const { lteGroup } = this.state;
		const { publication, invertForOther } = this.props;

		await invertForOther({
			publicationId: publication._id,
			lteGroup,
		});

		setTimeout(() => this.setState({ time: Date.now() }), 1000);
	}

	componentDidMount() {

		const { pubFeedsLoaded, getFeeds, publication } = this.props;

		if (!pubFeedsLoaded) {
			getFeeds({ publicationId: publication._id });
		}
	}

	componentDidUpdate() {

		const { pubFeedsLoaded, getFeeds, publication } = this.props;

		if (!pubFeedsLoaded) {
			getFeeds({ publicationId: publication._id });
		}
	}

	render() {

		const {
			publication,
			feeds,
			setFeed,
			deleteFeed,
			tagOpts,
			publicationTagOpts,
			submitImageUrl,
			uploadImage,
			lgLightLogoSrc,
			lgDarkLogoSrc,
			mdLightLogoSrc,
			mdDarkLogoSrc,
			smLightLogoSrc,
			smDarkLogoSrc,
		} = this.props;

		const {
			url,
			name,
			description,
			hasAggregate,
			images,
			newFeed,
			imageUrl,
			lteGroup,
			time,
		} = this.state;

		return (
			<div>
				<div className={ cx('btnRow') }>
					<button
						className={ cx('btnSave') }
						onClick={ this.save }
					>
						<i className='fa fa-plus' />
						&nbsp;Save
					</button>
					<button
						className={ cx('btnLive') }
						onClick={ this.toggleLive }
					>
						<span className={ cx({ live: publication.live }) }>&bull;</span>
						&nbsp;Live
					</button>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Logo</label>
					<div className={ sx('uploadSection') }>
						<div className={ s.logos }>
							<div className={ s.logo }>
								{
									publication.images.dark &&
									<div
										className={ sx('logoCont', { highlighted: lteGroup === 'dark' }) }
										onClick={
											() => this.setState({
												lteGroup: lteGroup === 'dark' ? '' : 'dark'
											})
										}
									>
										<Logo
											publicationId={ publication._id }
											dim={ '100' }
											bg={ 'dark' }
											time={ time }
										/>
									</div>
								}
								{
									!publication.images.dark &&
									<div
										className={ sx(
											'logoPlaceholder',
											{
												highlightedEmpty: lteGroup === 'dark',
												unhighlightedEmpty: lteGroup !== 'dark'
											}
										)}
										onClick={ () => this.setState({ lteGroup: 'dark' }) }
									>
										Dark
									</div>
								}
							</div>
							<div className={ s.logo }>
								{
									publication.images.light &&
									<div
										className={ sx('logoCont', { highlighted: lteGroup === 'light' }) }
										onClick={
											() => this.setState({
												lteGroup: lteGroup === 'light' ? '' : 'light'
											})
										}
									>
										<Logo
											publicationId={ publication._id }
											dim={ '100' }
											bg={ 'light' }
											time={ time }
										/>
									</div>
								}
								{
									!publication.images.light &&
									<div
										className={ sx(
											'logoPlaceholder',
											{
												highlightedEmpty: lteGroup === 'light',
												unhighlightedEmpty: lteGroup !== 'light'
											}
										)}
										onClick={ () => this.setState({ lteGroup: 'light' }) }
									>
										Light
									</div>
								}
							</div>
						</div>
						<div className={ sx('uploadCont', { hidden: lteGroup === '' }) }>
							<div className={ s.logoRow }>
								<input
									type='file'
									onChange={
										({ target }) => {
											const { validity, files } = target;
											const file = files[ 0 ];
											if (validity.valid) {
												const pl = {
													publicationId: publication._id,
													file,
													lteGroup
												}
												return this.uploadImage(pl);
											}
										}
									}
								/>
							</div>
							<div className={ s.logoRow }>
								<input
									type='text'
									placeholder='By URL'
									className={ sx('logoUrl') }
									onChange={ this.onChangeImageUrl }
								/>
								<button
									className={ sx('btnSubmitUrl') }
									onClick={ this.submitImageUrl }
								>
									Submit
								</button>
							</div>
							<div className={ s.logoRow }>
								<button
									className={ sx('btnSubmitUrl') }
									onClick={ this.invertForOther }
								>
									Invert for { lteGroup === 'light' ? 'dark' : 'light' }
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>URL</label>
					<input
						type='text'
						value={ url }
						className={ c.fieldInput }
						onChange={ this.onChangeUrl }
					/>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Name</label>
					<input
						type='text'
						value={ name }
						className={ c.fieldInput }
						onChange={ this.onChangeName }
					/>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Description</label>
					<textarea
						type='text'
						value={ description }
						className={ c.fieldTextarea }
						onChange={ this.onChangeDescription }
					/>
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Tags</label>
					<Select
						closeMenuOnSelect={ not(isEmpty(publicationTagOpts)) }
						isMulti
						defaultValue={ publicationTagOpts }
						options={ tagOpts }
						onChange={ this.onChangeTags }
					/>
				</div>
				<div className={ cx('fieldInlineRow') }>
					<label className={ c.fieldInlineLabel }>Aggregate feed</label>
					<input
						type='checkbox'
						onChange={ this.onChangeAggregate }
						checked={ hasAggregate }
					/> 
				</div>
				<div className={ cx('fieldRow') }>
					<label className={ c.fieldLabel }>Feeds</label>
					<div className={ cx('topInputRow') }>
						<input
							type='text'
							value={ newFeed }
							className={ c.fieldInput }
							onChange={ this.onChangeNewFeed }
							onKeyPress={ this.onNewFeedKeyPress }
						/>
						<button
							className={ cx('btnSubmit') }
							onClick={ this.addFeed }
						>
							<i className='fa fa-plus' />
							&nbsp;Add
						</button>
					</div>
					<Feeds
						publicationId={ publication._id }
						setFeed={ setFeed }
						deleteFeed={ deleteFeed }
					/>
				</div>
				
			</div>
		);
	}
}

export default connect(
	(state, props) => ({
		pubFeedsLoaded: workSels.pubFeedsLoaded(state, props),
		publicationTagOpts: workSels.getPublicationTagOpts(state, props),
		feeds: feedSels.getFeedsByPublicationId(state, props),
		tagOpts: tagSels.makeGetTagOpts()(state, props),
	}),
	{
		createPublication: pubActs.createPublicationRequest,
		updatePublication: pubActs.updatePublicationRequest,
		submitImageUrl: pubActs.submitImageUrlRequest,
		uploadImage: pubActs.uploadImageRequest,
		invertForOther: pubActs.invertForOtherRequest,
		getFeeds: feedActs.getPublicationFeedsRequest,
		createFeed: feedActs.createFeedRequest,
		deleteFeed: feedActs.deleteFeedRequest,
		setFeed: workActs.setFeed,
	},
)(PubsCtlForm);
