import { createSelector } from '@reduxjs/toolkit';
import * as r from 'ramda';

const self = state => state.feeds;

export const getFeedId = r.compose(r.prop('feedId'), r.nthArg(1));

export const getFeedsIds = createSelector(
    self,
    r.prop('allIds')
);

export const getFeedsMap = createSelector(
    self,
    r.prop('byId')
);

export const getPublicationId = r.compose(
    r.view(r.lensPath([ 'publication', '_id' ])),
    r.nthArg(1)
);

export const getFeedIdsByPublicationId = ({ feeds }, { publicationId }) =>
    feeds.idsByPublicationId[ publicationId ];

export const makeGetFeedIdsByPublicationId = () => createSelector(
    getFeedIdsByPublicationId,
    r.identity
);

export const getFeedById = createSelector(
    [ getFeedId, getFeedsMap ],
    (feedId, feeds) => feeds[ feedId ],
);

export const getPublicationIdByFeedId = createSelector(
    [ getFeedById ],
    feed => feed ? feed.publication._id : undefined
);

export const makeGetFeedById = () => createSelector(getFeedById, r.identity);

export const getFeedsByPublicationId = createSelector(
    [ getFeedsMap, getFeedIdsByPublicationId ],
    (feeds, feedIds) => r.compose(
        r.map(r.flip(r.prop)(feeds)),
        r.defaultTo([]),
    )(feedIds),
);

export const makeGetFeedsByPublicationId = () => createSelector(
    getFeedsByPublicationId,
    r.identity
);

export default {
    getFeedId,
    getFeedsIds,
    getFeedsMap,
    getPublicationId,
    getFeedIdsByPublicationId,
    makeGetFeedIdsByPublicationId,
    getPublicationIdByFeedId,
    getFeedById,
    makeGetFeedById,
    getFeedsByPublicationId,
    makeGetFeedsByPublicationId,
};
