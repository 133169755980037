import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';

import { bindThis } from '@lib/utils/react';
import publications from '@state/publications';
import workspace from '@mods/workspace/redux';
//import users from 'users';
import Tile from '@ui/components/tile';

import s from './styles.scss';
import c from '@ui/components/tile/styles.scss';

const sx = cn.bind(s);
const cx = cn.bind(c);

class BacklogCtl extends React.Component {

	constructor() {
		super();

		this.state = {
			url: '',
		};

		bindThis(this, [ 'onChange', 'onKeyPress', 'submit' ]);
	}

	onChange(e) {
		this.setState({ url: e.target.value });
	}

	onKeyPress(e) {

		if (e.key == 'Enter') {
			this.submit();
		}

		return false;
	}

	async submit(e) {

		const { url } = this.state;
		const { createPublication } = this.props;

		const publication = await createPublication({
			input: {
				url: url.replace(/\/$/,'')
			}
		});

		this.setState({ url: '' });
	}

	componentDidMount() {
		const { getPublicationBacklog } = this.props;
		getPublicationBacklog();
	}

	render() {

		const { publications, setPublication, deletePublication } = this.props;
		const { url } = this.state;

		return (
			<Tile
				title='Backlog'
				className={ sx('backlogCell') }
				flexGrow={ 2 }
			>
				<div className={ cx('topInputRow') }>
					<input
						type='text'
						value={ url }
						className={ cx('fieldInput') }
						onChange={ this.onChange }
						onKeyPress={ this.onKeyPress }
					/>
					<button
						className={ cx('btnSubmit') }
						onClick={ this.submit }
					>
						<i className='fa fa-plus' />
						&nbsp;Add
					</button>
				</div>
				<ul className={ cx('list') }>{
					publications.map(p => (
						<li key={ p._id } className={ cx('listItem') }>
							<span className={ cx('listItemContent') }>{ p.url }</span>
							<button
								className={ cx('btnView') }
								onClick={ () => setPublication({ publicationId: p._id }) }
							>
								View
							</button>
							<button
								className={ cx('btnDelete') }
								onClick={ () => deletePublication({ publicationId: p._id }) }
							>×</button>
						</li>
					))
				}</ul>
			</Tile>
		);
	}
}

export default connect(
	(state, props) => ({
		publications: publications.selectors.makeGetPublications()(state, props),
	}),
	{
		createPublication: publications.actions.createPublicationRequest,
		getPublicationBacklog: publications.actions.getPublicationBacklogRequest,
		setPublication: workspace.actions.setPublication,
		deletePublication: publications.actions.deletePublicationRequest,
	},
)(BacklogCtl);
