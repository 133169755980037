import { createSelector } from '@reduxjs/toolkit';
import {
	compose as c, curry, filter, flip, has, identity,
	lensPath, nthArg, prop, map,
	tap, view,
} from 'ramda';

import { selectors as $feeds } from '@state/feeds';
import { APP_BASE_URL } from '@src/env';

const getPublicationById = curry(
    (publicationsMap, publicationId) => prop(publicationId, publicationsMap)
);

const getByIds = (publications, ids) => map(flip(prop)(publications), ids);

const self = state => state.publications;

export const getBg = c(prop('bg'), nthArg(1));

export const getLogoDim = c(prop('dim'), nthArg(1));

export const getPublicationId = c(prop('publicationId'), nthArg(1));

export const getPublicationsIds = createSelector(self, prop('allIds'));

export const getPublicationsMap = createSelector(self, prop('byId'));

export const getPublication = createSelector(
    [ getPublicationsMap, getPublicationId ],
    c(getPublicationById),
);

export const getPublicationIdDeep = createSelector(
    [ getPublicationId, $feeds.getPublicationIdByFeedId ],
    (publicationId, publicationIdByFeedId) => publicationId || publicationIdByFeedId
);

export const getPublicationByFeedId = createSelector(
    [ $feeds.getFeedById, getPublicationsMap ],
    (feed, publications) => publications[ feed.publicationId ]
);

export const getPublications = createSelector(
    [ getPublicationsMap, getPublicationsIds ],
    c(getByIds),
);

export const makeGetPublicationByFeedId = () => createSelector(
    getPublicationByFeedId,
    identity
);

export const makeGetPublications = () => createSelector(
    getPublications,
    identity
);

export const makeGetPublication = () => createSelector(
    getPublication,
    identity
);

export const getLogoSrc = createSelector(
    [ getPublicationIdDeep, getLogoDim, getBg ],
    (publicationId, dim, bg) =>
        `${ APP_BASE_URL }/imgs/logos/${ publicationId }_${ bg }_${ dim }x${ dim }.png`,
);

export default {
    getBg,
    getLogoDim,
    getPublicationId,
    getPublicationsIds,
    getPublicationsMap,
    getPublication,
    getPublicationIdDeep,
    getPublicationByFeedId,
    getPublications,
    makeGetPublicationByFeedId,
    makeGetPublications,
    makeGetPublication,
    getLogoSrc,
};
