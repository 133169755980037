import * as r from 'ramda';

export default {
	byId: {
		'app.getUserDomainSuccess': (state, action) => {
            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;
			feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
		'search.popularSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
		'search.searchSuccess': (state, action) => {
            const { payload } = action;
            const { result } = payload;
			result.feeds.forEach(feed => {
				state[ feed._id ] = feed;
			});
		},
        createFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            state[ feed._id ] = feed;
        },
        getFeedsSuccess: (state, action) => {

            const feeds = action.payload.feeds;

            feeds.forEach(feed => {
                state[ feed._id ] = feed;
            });
        },
        getPublicationFeedsSuccess: (state, action) => {

            const feeds = action.payload.feeds;

            feeds.forEach(feed => {
                state[ feed._id ] = feed;
            });
        },
        deleteFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            delete state[ feed._id ];
        },
	},
	allIds: {
		'app.getUserDomainSuccess': (state, action) => {

            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;
			const feedIds = r.compose(
				r.map(r.prop('_id')),
			)(feeds);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.popularSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const feedIds = r.compose(
				r.map(r.prop('_id')),
				r.prop('feeds')
			)(result);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
		'search.searchSuccess': (state, action) => {

            const { payload } = action;
            const { result } = pyaload;

			const feedIds = r.compose(
				r.map(r.prop('_id')),
				r.prop('feeds')
			)(result);

			const ids = new Set([ ...state, ...feedIds ]);

			state.splice(0, state.length, ...ids);
		},
        createFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            let newState = [ ...state ];
            newState.push(feed._id);
            state = r.uniq(newState);
        },
        getFeedsSuccess: (state, action) => {

            const feeds = action.payload.feeds;

            feeds.forEach(feed => {
                state.push(feed._id);
            });
        },
        getPublicationFeedsSuccess: (state, action) => {

            const feeds = action.payload.feeds;

            feeds.forEach(feed => {
                state.push(feed._id);
            });
        },
        deleteFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            state.splice(state.findIndex(id => feed._id === id), 1);
        },
	},
	idsByPublicationId: {
		'app.getUserDomainSuccess': (state, action) => {

            const { payload } = action;
            const { userDomainResult: { feeds }} = payload;

			feeds.forEach(feed => {
				if (!(feed.publication._id in state)) {
					state[ feed.publication._id ] = [];
				}
				if (!state[ feed.publication._id ].includes(feed._id)) {
					state[ feed.publication._id ].push(feed._id);
				}
			});
		},
		'search.popularSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const { feeds } = result;

			for (let feed of feeds) {
				if (!(feed.publication._id in state)) {
					state[ feed.publication._id ] = [];
				}
				if (!state[ feed.publication._id ].includes(feed._id)) {
					state[ feed.publication._id ].push(feed._id);
				}
			}
		},
		'search.searchSuccess': (state, action) => {

            const { payload } = action;
            const { result } = payload;
			const { feeds } = result;

			for (let feed of feeds) {
				if (!(feed.publication._id in state)) {
					state[ feed.publication._id ] = [];
				}
				if (!state[ feed.publication._id ].includes(feed._id)) {
					state[ feed.publication._id ].push(feed._id);
				}
			}
		},
        createFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;
			state[ feed.publication._id ] = r.compose(
				r.uniq,
				r.append(feed._id),
				r.prop(feed.publication._id),
			)(state);
		},
        getFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;
			state[ feed.publication._id ] = feed._id;
		},
        getFeedsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds }= payload;

            return r.compose(
                r.mapObjIndexed(r.uniq),
                r.mergeWith(r.concat, state),
                r.mapObjIndexed(r.map(r.prop('_id'))),
                r.groupBy(r.view(r.lensPath([ 'publication', '_id' ]))),
            )(feeds);
        },
        getPublicationFeedsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds }= payload;

            return r.compose(
                r.mapObjIndexed(r.uniq),
                r.mergeWith(r.concat, state),
                r.mapObjIndexed(r.map(r.prop('_id'))),
                r.groupBy(r.prop('publicationId')),
            )(feeds);
        },
        getFeedsByIdsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

            return r.compose(
                r.mapObjIndexed(r.uniq),
                r.mergeWith(r.concat, state),
                r.mapObjIndexed(r.map(r.prop('_id'))),
                r.groupBy(r.view(r.lensPath([ 'publication', '_id' ]))),
            )(feeds)
        },
        deleteFeedSuccess: (state, action) => {
            const { payload } = action;
            const { feed } = payload;
			const { _id, publicationId } = feed;

			if (r.has(publicationId, state)) {
				state[ publicationId ] = r.reject(
                    r.equals(_id),
                    state[ publicationId ]
                );
			}
		}
	},
    publicationIdsByFeedId: {
        createFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            state[ feed._id ] = feed.publication._id;
        },
		getFeedSuccess: (state, action) => {

            const { payload } = action;
            const { feed } = payload;

			state[ feed._id ] = feed.publication._id;
		},
        getFeedsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

			feeds.forEach(feed => {
				state[ feed._id ] = feed.publication._id;
			});
		},
        getFeedsByIdsSuccess: (state, action) => {

            const { payload } = action;
            const { feeds } = payload;

			feeds.forEach(feed => {
				state[ feed._id ] = feed.publication._id;
			});
		},
        deleteFeedSuccess: (state, action) => {
            const feed = action.payload.feed;
            delete state[ feed._id ];
        },
	},
};
