import { fork } from 'redux-saga/effects';

import { saga as auth } from '@state/auth';
import { saga as users } from '@state/users';
import { saga as publications } from '@state/publications';
import { saga as feeds } from '@state/feeds';
import { saga as tags } from '@state/tags';
import { saga as socketio } from '@state/socketio';
//import { saga as search } from '@mods/search/redux';

const sagas = {
    auth,
    users,
    publications,
    feeds,
    tags,
    socketio,
    //search,
};

export default function* () {

    const pairs = Object.entries(sagas);

    for (let [ modId, saga ] of pairs) {
        try {
            yield fork(saga);
        } catch (e) {
            console.log(`Redux Saga: Error in module [${ modId }]`, e);
        }
    }
};
