import Workspace from '@ui/components/workspace';
import Tags from '../tags';
import Backlog from '../backlog';
import Feeds from '../feeds';
import Publications from '../publications';

export default ctx => {
    return (
        <Workspace>
            <Tags />
            <Backlog />
            <Publications />
            <Feeds />
        </Workspace>
    );
};
