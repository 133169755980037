import { format } from 'date-fns';

const tf = v => v ? 'Yes' : 'No';

export default [
	{
		key: '_id',
		label: 'ID',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
	{
		key: 'publication',
		label: 'Publication',
		visible: true,
		sort: 0,
		filter: {
			type: 'list',
			multi: true,
			value: [],
			options: [],
		},
	},
	{
		key: 'title',
		label: 'Title',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
    {
		key: 'url',
		label: 'URL',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
    {
		key: 'description',
		label: 'Description',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
    {
		key: 'live',
		label: 'Live',
		visible: true,
		sort: 0,
		getValue: f => tf(f.live),
		filter: {
			type: 'boolean',
			value: undefined,
		},
        editable: true,
	},
    {
		key: 'aggregate',
		label: 'Aggregate',
		visible: true,
		sort: 0,
		getValue: f => tf(f.aggregate),
		filter: {
			type: 'boolean',
			value: undefined,
		},
        editable: true,
	},
	{
		key: 'lastPolled',
		label: 'Last Polled',
		visible: true,
		sort: 0,
		getValue: f => {
			if (f.lastPolled) {
				return format(
					new Date(parseInt(f.lastPolled)),
					'MMM do yyyy, h:mm aaa'
				);
			}
			return '';
		},
		filter: {
			type: 'date',
			value: [ '', '' ],
		},
	},
    {
		key: 'lastSuccessfulPoll',
		label: 'Last Successful Poll',
		visible: true,
		sort: 0,
		getValue: f => {
			if (f.lastSuccessfulPoll) {
				return format(
					new Date(parseInt(f.lastSuccessfulPoll)),
					'MMM do yyyy, h:mm aaa'
				);
			}
			return '';
		},
		filter: {
			type: 'date',
			value: [ '', '' ],
		},
	},
	{
		key: 'fetchErrors',
		label: 'Fetch Errors',
		visible: true,
		sort: 0,
		filter: {
			type: 'number',
			value: '',
		},
	},
	{
		key: 'lastError',
		label: 'Last Error',
		visible: true,
		sort: 0,
		filter: {
			type: 'text',
			value: '',
			buf: '',
		},
	},
	{
		key: 'lastErrorDate',
		label: 'Last Error Date',
		visible: true,
		sort: 0,
		getValue: f => {
			if (f.lastErrorDate) {
				return format(
					new Date(parseInt(f.lastErrorDate)),
					'MMM do yyyy, h:mm aaa'
				);
			}
			return '';
		},
		filter: {
			type: 'date',
			value: [ '', '' ],
		},
	},
];
