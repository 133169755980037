import {
	always, append, applySpec, compose as c,
	flip, has, ifElse, lensPath, reduce, view,
} from 'ramda';
import { useEffect, useRef } from 'react';

export const bindThis = (obj, fns) => fns.forEach(fn => (
	obj[ fn ] = obj[ fn ].bind(obj))
);

export const useIsFirstRender = () => {

	const isFirstRef = useRef(true);
	
	useEffect(
		() => {
			isFirstRef.current = false;
		},
		[]
	);

	return isFirstRef.current;
};
					

export const useClickAway = (ref, callback) => {

	useEffect(
		() => {
			const handleClickOutside = e => {
				if (ref.current && !ref.current.contains(e.target)) {
					callback();
				}
			}
			document.addEventListener('mouseup', handleClickOutside);

			return () => {
				document.removeEventListener('mouseup', handleClickOutside);
			};
		},
		[ ref ]
	);
}

export const makeSelectOpts = (ids, objs, labelProp) => reduce(
	(opts, id) => [
		...opts,
		{
			value: id,
			label: objs[ id ][ labelProp ]
		}
	],
	[],
    ids
);

export const basicOpts = (ids, objs, labelProp) => c(
	reduce(
		(opts, id) => c(
			ifElse(
				has(id),
				c(
					flip(append)(opts),
					applySpec({
						value: always(id),
						label: view(lensPath([ id, labelProp ])),
					})
				),
				always(opts)
			),
		)(objs),
		[],
	),
)(ids);
