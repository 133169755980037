import { eventChannel, END } from 'redux-saga';
import {
    call, cancel, cancelled, delay, fork,
    race, take, put, takeLatest
} from 'redux-saga/effects'

import getSocketio from '@lib/socketio';
import { groupActions, createAPISaga } from '@lib/utils/redux';
import { actions } from './';
import $auth from '@state/auth';

const _connect = (accessToken, nsp = '/') => new Promise((resolve, reject) => {

    const socketio = getSocketio();

    const socket = socketio.manager.socket(nsp, {
        auth: {
            token: accessToken
        }
    });

    socket.connect();

    socket.on('connect', () => {
        console.log('Socket connected to', nsp, socket.id);
        resolve(socket);
    });

    socket.on('connect_error', res => {
        if (!res.data) {
            reject(new Error('Unknown connection error'));
        } else {
            if (res.data.data) {
                reject(new Error(res.data.data.type, { cause: res.data.data }));
            } else {
                reject(new Error(res.data));
            }
        }
        socket.removeAllListeners();
    });
});

const tryConnect = function* (accessToken, nsp) {

    let seconds = 2000;

    while (true) {
        try {
            const { timeout, socket } = yield race({
                socket: call(_connect, accessToken, nsp),
                timeout: delay(4000),
            });
            
            if (timeout || !socket) {
                yield delay(seconds);
            } else {
                return socket;
            }
        } catch (e) {
            switch (e.message) {

                case 'TokenExpiredError':
                case 'JsonWebTokenError':
                case 'NotBeforeError':
                    return e;
                default:
                    yield delay(seconds);
                    break;
            }
        } finally {
            seconds *= 2;
        }
    }
};

const disconnectChannel = socket => eventChannel(emit => {

    const priorState = {
        id: socket.id,
        nsp: socket.nsp,
    };

    const disconnectHandler = reason => {
        console.log('Socket disconnected. Reason:', reason);
        emit(priorState);
    };

    socket.on('disconnect', disconnectHandler);

    return () => {
        socket.off('disconnect', disconnectHandler);
        socket.off('connect');
    };
});

const listenDisconnect = function* (socket) {

    const disconnectChan = yield call(disconnectChannel, socket);

    try {
        while (true) {

            const socket = yield take(disconnectChan);

            yield put(actions.socketDisconnected({ socket }));
        }
    } finally {
        disconnectChan.close();
    }
};

const connectWorker = function* (action) {

    const { payload: { accessToken, nsp }} = action;

    try {
        const socketio = getSocketio();
        const socket = yield call(tryConnect, accessToken, nsp);

        if (socket instanceof Error) {
            throw socket;
        }

        socketio.addSocket(socket);

        yield put(actions.socketConnected({
            socket: {
                id: socket.id,
                nsp: socket.nsp
            }
        }));

        yield fork(listenDisconnect, socket);

    } catch (e) {
        console.log('Socket.io: connection failure', e);

        switch (e.message) {

            case 'TokenExpiredError':
                yield put($auth.actions.refreshRequest());
                break;

            case 'JsonWebTokenError':
            case 'NotBeforeError':
                break;
        }
    }
};

const disconnectWorker = function* (action) {

    try {
        const socketio = getSocketio();
        const { id, nsp } = action.payload;
        const socket = socketio.getSocket(nsp);
        socket.disconnect();

    } catch (e) {
        console.log('Socket disconnect failure', e);
    }
};

const connect = function* () {
    yield takeLatest(actions.connect, connectWorker);
};

const disconnect = function* () {
    yield takeLatest(actions.disconnect, disconnectWorker);
};

export default function* () {

    const sagas = [
        connect,
        disconnect,
    ];

    for (let saga of sagas) {
        yield fork(saga);
    }
};
