import { connect } from 'react-redux';
import { compose as c } from 'ramda';

import app from '@state/app';

import BaseHeader from '@ui/components/header';
//import Sidebar from '@components/sidebar';

import s from './styles.scss';

const DesktopLayout = ({ header: Header, workspace: Workspace }) => (
	<div className={ s.root }>
		<BaseHeader>
            <Header />
        </BaseHeader>
		<main className={ s.mainCont }>
            <Workspace />
		</main>
	</div>
);

const MobileLayout = ({ header: Header, workspace: Workspace }) => (
	<div className={ s.root }>
		<BaseHeader>
            <Header />
        </BaseHeader>
		<main className={ s.mainCont }>
            <Workspace />
		</main>
	</div>
);

export default connect(
	(state, props) => ({
		responsive: app.selectors.getResponsive(state, props),
	}),
	{
	}
)(
	c(
		({ responsive, ...props }) => {


            const md = responsive ? responsive.md : true;

			if (md) {
				return <DesktopLayout { ...props }/>;
			}

			return <MobileLayout { ...props }/>;
		}
	)
);
