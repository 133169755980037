import { createActions, createReducer } from '@lib/utils/redux';
import config from '@nl/users/config.json';
import actions from './actions';
import reducers from './reducers';
import saga from './sagas';
import selectors from './selectors';

import { actions as app } from '@state/app';
import { actions as auth } from '@state/auth';

const reducerImports = {
    app,
    auth,
};

const reducer = createReducer(config, actions, reducers, reducerImports);

export { actions };
export { saga };
export { selectors };
export { reducer };

export default {
    actions,
    saga,
    selectors,
    reducer,
};
