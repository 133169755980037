import { getHistory } from '@lib/history-manager';
import s from './styles.scss';

export default ({ href, children, className }) => {

	const history = getHistory();

	const onClick = e => {
		e.preventDefault();
		history.push(href);
	};

    const linkClass = className || s.noLinkStyle;

	return (
		<a href={ href } onClick={ onClick } className={ linkClass }>
			{ children }
		</a>
	);
};
