import { useEffect, useRef } from 'react';

export const useIsFirstRender = () => {

	const isFirstRef = useRef(true);
	
	useEffect(
		() => {
			isFirstRef.current = false;
		},
		[]
	);

	return isFirstRef.current;
};
					

export const useClickAway = (ref, callback) => useEffect(
    () => {
        const handleClickOutside = e => {
            if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        }
        document.addEventListener('mouseup', handleClickOutside);

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    },
    [ ref ]
);
