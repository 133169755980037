import SocketioManager from '@lib/utils/socketio';
import { SOCKETIO_URL } from '@src/env';

const env = {
    URL: SOCKETIO_URL,
    PATH: '/broker',
};

let manager;


/*const socket = manager.socket('/poller');

socket.on('connect', res => {
    console.log('connected to server', socket.id, res);
});

socket.on('status', console.log);*/

export default userId => {
    if (manager) {
        return manager;
    }

    if (userId) {
        manager = new SocketioManager(env, userId);
    }

    return manager;
};
