import { useState, useRef, useCallback } from 'react';
import { useFloating, offset, size, flip, arrow } from '@floating-ui/react';
import cn from 'classnames/bind';

import { useClickAway } from '@ui/react-utils';

import s from './styles.scss';

const sx = cn.bind(s);

export default ({ value, options, classNames = {}, onChange = () => {} }) => {

	const clickAwayRef = useRef(null);
	const arrowRef = useRef(null);

	const {
		x,
		y,
		refs,
        floatingStyles,
		strategy,
		update,
		middlewareData: {
			arrow: {
				x: arrowX,
				y: arrowY,
			} = {}
		},
	} = useFloating({
		placement: 'bottom',
		middleware: [
			offset(7),
			flip(),
			arrow({ element: arrowRef }),
			size({
				apply: (ctx) => {
					const { availableWidth, reference, elements } = ctx;
					Object.assign(elements.floating.style, {
						minWidth: `${ elements.reference.offsetWidth }px`,
					});
				},
			}),
		],
	});

	let { valueContOuter, valueCont, menu, menuItem } = classNames;

	const _classNames = Object.entries({
		valueContOuter,
		valueCont,
		menu,
		menuItem,
	}).reduce(
		(cns, [ label, value ]) => {

			if (value) {
				if (Array.isArray(value)) {
					return {
						...cns,
						[ label ]: [ sx(label), ...value ],
					};
				} else if (typeof value === 'object' ) {
					if (value.overwrite) {
						return { ...cns, [ label ]: value.value };
					} else {
						if (Array.isArray(value.value)) {
							return { ...cns, [ label ]: [ sx(label), ...value.value ] };
						} else {
							return { ...cns, [ label ]: [ sx(label), value.value ] };
						}
					}
				} else {
					return { ...cns, [ label ]: [ sx(label), value ]};
				}
			}

			return { ...cns, [ label ]: sx(label) };
		},
		{}
	);

	const [ menuOpen, setMenuOpen ] = useState(false);

	useClickAway(clickAwayRef, () => setMenuOpen(false));

	return (
		<div ref={ refs.setReference }>
			<div ref={ clickAwayRef }>
				<div className={ cn(_classNames.valueContOuter) }>
					<div
						className={ cn(_classNames.valueCont) }
						onClick={ e => {
							setMenuOpen(!menuOpen);
						}}
					>
						{ value ? value.label : '' }
					</div>
				</div>
				 {
					menuOpen &&
					<div
						className={ cn(_classNames.menu) }
						ref={ refs.setFloating }
						style={ floatingStyles }
					>
						<div
							ref={ arrowRef }
							className={ s.arrow }
							style={{
								position: strategy,
								top: arrowY ?? -5,
								left: arrowX ?? 0,
							}}
						/>
						{
							!!options.length && options.map((o, i) => {
								return (
									<div
										key={ i }
										className={ cn(_classNames.menuItem) }
										onClick={
											e => {
												onChange(o);
												setMenuOpen(false);
											}
										}
									>
										{ o.label }
									</div>
								);
							})
						}
						{
							!options.length &&
							<div className={ s.noOptions }>No options</div>
						}
					</div>
				}
			</div>
		</div>
	);
};
