import { createSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

const ident = state => state.app;

const propSelector = p => createSelector(ident, prop(p));

export const isLoading = propSelector('loading');

export const getResponsive = propSelector('responsive');

export default {
    isLoading,
    getResponsive,
};
