export default {
    toggleLoading: (state, action) => {
        state.loading = !state.loading;
    },
    setResponsive: (state, action) => {
        const { payload } = action;
        state.responsive = {
            xs: payload.xs,
            sm: payload.sm,
            md: payload.md,
            lg: payload.lg,
            xl: payload.xl,
            portrait: payload.portrait,
            retina: payload.retina,
        };
    },
};
