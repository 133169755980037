import getSocketio from '@lib/socketio';
import { useEffect, useState } from 'react';

import s from './styles.scss';

export default worker => {

    const [ status, setStatus ] = useState(worker.status);
    const [ inProgress, setInProgress ] = useState(worker.inProgress);

    let socket, socketio;

    useEffect(
        () => {

            socketio = getSocketio();

            if (socketio) {

                socket = socketio.getSocket('/poller');
                
                if (socket) {
                    socket.on('inProgress', ({ id, inProgress }) => {
                        if (id === worker.id) {
                            setInProgress(inProgress)
                        }
                    });
                }
            }

            return () => {
                if (socket) {
                    socket.off('inProgress');
                }
            };
        },
        [ socket, socketio ]
    );

    return (
        <div className={ s.root }>
            <div>ID: { worker.id }</div>
            <div>Status: { status }</div>
            <div>InProgress: { inProgress.length }</div>
            <div>Feeds:</div>
            <div className={ s.feeds }>
                {
                    inProgress.map(
                        feed => (
                            <div key={ feed._id }>
                                { feed.url }
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
};
