import cn from 'classnames/bind';
import s from './styles.scss';

const sx = cn.bind(s);

export default ({ title, className, flexGrow, children }) => (
    <article className={ sx('cell', { [ `flexGrow${ flexGrow }` ]: true }) }>
        <header className={ s.title }>{ title }</header>
        <div className={ `${ s.cellContent } ${ className }` }>
            { children }
        </div>
    </article>
);
